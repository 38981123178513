@import "../../../sass/globals";

.settings {
  margin-top: $margin-top-header;
  margin-bottom: 150px;
}

.account-settings {
  background-color: #ffffff;
  position: relative;
  border: $border-card;
  padding: 10px 15px 20px 15px;
  border-radius: 20px;
}

.section-title {
  color: $text-1;
  font-size: 25px;
  font-weight: bold;
  line-height: 33px;
  padding: 17px 45px;
}
.account-settings-info {
  padding: 0 0 0 100px;
}

.account-settings-info .section-sub-title {
  font-weight: bold;
  font-size: 19px;
  color: $text-1;
}

.account-settings-info .form-group {
  margin: 0;
}

.account-settings-sub-info {
  padding: 20px 100px 20px 0;
  max-width: 800px;
}

.account-settings-submit-info .btn__homepage-white {
  color: #0073b2 !important;
}

.account-settings-submit-info .btn__homepage-blue {
  color: white !important;
}

.account-settings-submit-info .btn__homepage-blue:hover {
  color: #0073b2 !important;
}

.account-settings-submit-info button.btn__homepage {
  outline: none;
}

.settings-separator {
  position: absolute;
  left: 0;
  height: 1px;
  background: $gray-7;
}

.section-label {
  color: $text-1;
}

.password,
.email {
  padding-left: 20px;
  border-radius: 28px;
}

.account-settings-button {
  border-radius: 23px;
  padding: 12px 40px;
  height: 45px;
}

@media (max-width: 768px) {
  .settings {
    > .section-title {
      font-size: 25px;
      margin-left: 15px;
    }
  }
  .account-settings {
    .section-title {
      padding: 10px 0 !important;
    }
  }
  .section-title,
  .account-settings-info {
    margin: 0;
    padding: 0 !important;
  }
  .account-settings-sub-info {
    padding: 20px 10px;
  }
  .form-control {
    border-radius: 8px;
  }
  .wrapper,
  .settings--footer {
    &.open {
      display: none;
    }
  }
}

@import "../../../../sass/globals.scss";

.filter-wrapper {
  display: flex;
  align-items: center;
}

.filter-placeholder {
  height: 41px;
}

.section-title {
  font-size: 24px;
  margin-right: 20px;
  margin-bottom: 0;
}

.filter-btn-group button {
  color: #434343;
  background-color: #d9d9d9;
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 600;
  padding: 4px 16px;
  margin-right: 20px;
  font-size: 16px;
  min-width: 50;
}

.filter-btn-group button:focus {
  box-shadow: none;
}

.filter-btn-group .btn-primary:not(:disabled):not(.disabled).active,
.filter-btn-group .btn-primary:not(:disabled):not(.disabled):active,
.filter-btn-group .show > .btn-primary.dropdown-toggle {
  background-color: #434343;
  color: #fff;
}

.filter-btn-group .btn-primary:hover,
.filter-btn-group .btn-primary.focus,
.filter-btn-group .btn-primary:focus {
  background-color: #434343;
  color: #fff;
  box-shadow: none;
}

.filter-btn-group .btn-primary:disabled {
  color: #fff;
  background-color: #aaaaaa;
  border-color: #aaaaaa;
}

.sort {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
}

.sort .custom-select {
  padding: 5px 20px 5px 10px;
  width: initial;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
}

.sort .select-wrapper {
  position: relative;
}

.sort .select-wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.sort .custom-select:focus {
  border: none;
  box-shadow: none;
}

.sort span {
  color: #888888;
}

.filter-btn {
  display: flex;
  justify-content: flex-end;
}

.filter {
  margin: 0;
}

.filter-desktop {
  display: block;
}
.filter-dropdown--wrapper {
  display: none;
}

@media (max-width: 991px) {
  .section-title {
    font-size: 18px;
  }
  .filter-btn-group button {
    padding: 5px 15px;
    margin-right: 12px;
    font-size: 15px;
  }
}

@media (max-width: 767px) {
  .section-title {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .filter-wrapper {
    flex-wrap: wrap;
  }
  .filter-btn-group {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .filter-btn-group button {
    margin-right: 0;
  }
  .filter {
    overflow: scroll;
  }
  .filter-desktop {
    display: none;
  }
  .filter-dropdown--wrapper {
    display: block;
    width: 100%;

    .navbar-dropdown.filter-dropdown {
      position: relative;
      top: initial;
      margin-right: auto;
      background-color: $white;

      a.dropdown-toggle {
        min-width: 200px;
        text-align: center;
      }

      .dropdown-menu {
        right: 0 !important;

        .filter-dropdown-item-enabled {
          background: $blue;
          color: $white;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .filter-btn-group button {
    font-size: 15px;
  }
}

.filter--button {
  background-color: $gray-3 !important;
  color: $text-2;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  min-height: 41px;
  padding: 9px 21px !important;
  margin-bottom: 20px;

  &-active,
  &:active {
    background-color: $brand !important;
  }
  &:hover {
    color: $text-2 !important;
    background-color: inherit;
  }

  &-disable {
    background-color: $gray-3 !important;
    color: $text-1 !important;
  }

  &-highlight {
    background-color: $success !important;
    color: $white !important;
    &:hover {
      color: $white !important;
    }
  }

  &-active {
    color: $white !important;
    &:hover {
      color: $white !important;
    }
  }
}

.filter-badge {
  width: 40px;
  margin-left: 10px;
}

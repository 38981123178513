@import "../../../../sass/globals.scss";

.engagement-buttons {
  &--items {
    display: flex;
    justify-content: space-around;
    border-top: $border-card;
    border-bottom: $border-card;
  }
  &--item {
    margin-right: 10px;
    margin: 5px 10px 5px 0;
    background: transparent;
    border: none;

    &__higlighted {
      color: $brand;
      font-weight: 500;
    }

    &.checked {
      color: $brand;
      font-weight: 500;
    }
    &-image {
      padding-right: 5px;
      width: 20px;
      height: 20px;
    }

    @media screen and (max-width: 575px) {
      font-size: 14px;
    }
  }
  &--stats {
    display: flex;
  }
}

.engagement-stats {
  &--wrapper {
    display: flex;
    padding-left: 3px;
  }
}

.engagement-stat {
  &--wrapper {
    color: $text-1;
    &:hover {
      color: $text-1;
    }
  }
}

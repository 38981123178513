@import "../../../../sass/globals.scss";

.simple-top-banner {
  margin-bottom: 20px;

  &--img {
    border-radius: 12px;
    margin-right: 23.5px;
    float: left;
  }

  &--text {
    color: $text-1;
  }

  &--subtitle {
    color: $text-1;
    font-size: 18px;
    line-height: 20px;
  }

  &--wrapper {
    width: 100%;
    min-height: 164px;
    padding: 16px ​0 0 16px;
  }

  &--background-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 164px;
  }
}

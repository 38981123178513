// animation
.ityped-cursor {
  font-size: 40px;
  opacity: 1;
  -webkit-animation: blink 0.3s infinite;
  -moz-animation: blink 0.3s infinite;
  animation: blink 0.3s infinite;
  animation-direction: alternate;
}

@keyframes blink {
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  100% {
    opacity: 0;
  }
}

.animationText {
  background-image: linear-gradient(120deg, #cce3ef, #cce3ef);
  background-repeat: no-repeat;
  background-position: 0 78%;
  background-size: 100% 11px;
  display: inline;
}

@import "../../../sass/globals.scss";

.button-as-link {
  color: #2962ff;
  text-decoration: none;
  background-color: transparent;
  border: none;
  font-weight: 600;
  padding-left: 0px;
  padding-right: 0px;

  &:active,
  &:hover,
  &:focus,
  &:visited {
    border: none !important;
    background-color: unset !important;
    color: #2962ff !important;
  }
}

.btn__nux_share {
  font-size: 14px;
  font-weight: 600;
  height: 35px;
  line-height: 1;
  margin-top: -3px;
  padding-top: 7px;
  margin-left: auto;
  width: 145px;
}

.nux-modal {
  max-width: 700px;
}

.nux-modal-input {
  height: 40px;
}

.feed-profile-check {
  scale: 0.8;
  margin-top: -2px;
  margin-left: 2px;
}

.feed-dashboard-header {
  background: white;
  padding: 10px;
  margin-bottom: 0;
  /* box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 0px 3px rgba(0,0,0,.2); */
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  margin-left: -1px;
  margin-right: -1px;
}

.feed-dashboard-cell {
  margin-bottom: 0px;
  box-shadow: none;
}

.feed-dashboard-show-more {
  padding-left: 0px;
  padding-right: 0px;
  background-color: #fff;
  border-radius: 2px;
  margin: 0;
  padding-bottom: 10px;
  border-top: 2px solid #ccc;
}

.feed-transition-enter {
  opacity: 0;
}

.feed-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.feed-transition-exit {
  opacity: 1;
}

.feed-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.feed-divider {
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 16px;
  margin-bottom: 5px;
  padding-bottom: 6px;
}

.feed-top-category {
  font-size: 18px;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 4px;
}

.feed-top-category a {
  font-size: 14px;
  color: #888;
}

.feed-top-category-lh {
  line-height: 20px;
  margin-top: 4px;
  margin-bottom: 8px;
}

.feed-leader-link {
  font-size: 18px;
  margin-left: 15px;
  cursor: pointer;
  margin-bottom: 4px;
  line-height: 25px;
}

.feed-leader-link a {
  color: #676767;
}

.feed-sub-category {
  font-size: 14px;
  margin-left: 30px;
  color: #888;
}

.feed-sub-category a {
  color: #888;
}

.feed-profile-card-divider {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-top: 2px;
  margin-bottom: 5px;
  height: 1px;
  background-color: lightgray;
}

.feed-activity-indicator {
  scale: 1.1;
}

.feed-dashboard-cell .article-img img {
  width: 60px;
  height: 60px;
}

.feed-dashboard-cell .article-subheadlines {
  max-width: 95%;
}

.feed-dashboard-cell .article-subheadline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.feed-subscribed-label {
  text-align: center;
  float: right;
  width: 130px;
  font-weight: 600;
  color: $brand;
}

.feed-category-box {
  border: 1px solid #cccccc;
  padding: 10px;
  background: white;
  margin-top: 6px;
  padding-right: 20px;
}

.feed-category-box-title {
  font-size: 20px;
  margin-left: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.feed-right-container {
  padding-right: 0px;
  padding-left: 0px;
  min-width: 250px;
  max-width: 250px;
}

.feed-box {
  border: 1px solid #cccccc;
  background: white;
}

.feed-box-margin-top {
  margin-top: 10px;
}

.feed-box-title {
  color: black;
  font-size: 20px;
  padding: 10px;
}

.feed-box-content-item {
  padding: 10px 20px;
  border-top: 1px solid #cccccc;
}

.feed-box-content-item-special {
  border-top: none;
  padding: 5px 10px;
}

.feed-box-content-icon {
  color: green;
}

.feed-box-content-text {
  padding-left: 20px;
  color: black;
}

.feed-box-content-text-checked {
  text-decoration: line-through;
  color: grey;
}

.feed-box-content-name {
  font-weight: bold;
}

.feed-box-content-role {
  color: gray;
  margin-top: -5px;
}

.no-feed-data-header {
  text-align: center;
  font-weight: 600;
  padding-top: 25px;
  padding-bottom: 25px;
}

/* New Styles */
.my-network-text {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  color: $text-1;
  padding: 12px 0;
}

.my-network-img {
  background-color: $light-blue;
  padding: 22.5px 23px;
  border-radius: 100%;
  max-width: fit-content;
  margin: auto;
}

.about-this-space {
  font-size: 16px;
  color: $text-2;
  line-height: 24px;
  padding-bottom: 8px;
}

.top-banner-enter {
  opacity: 0;
}
.top-banner-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.top-banner-exit {
  opacity: 1;
}
.top-banner-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.top-banner-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.top-banner-button {
  min-width: 228px;
}

.feed-page-top-banner {
  .custom-card:first-child {
    flex: 1;
    margin-bottom: 0px;
  }
  .custom-card.top-banner--right-column {
    margin-bottom: 0px;
  }
}

.feed-page-top-banner-social {
  @media screen and (max-width: 767px) {
    &.align-items-center {
      align-items: flex-start !important;
    }
  }
}

.feed-page-top-banner-img {
  border-radius: 12px;
  margin-right: 23.5px;

  @media screen and (max-width: 767px) {
    margin-top: 10px;

    img {
      width: 40px !important;
      height: 40px !important;
    }
  }
}

.feed-page-top-banner-placeholder-img {
  background-color: $light-blue;
  border-radius: 12px;
  padding: 27.5px 31.5px 27.5px 27px;
  margin-right: 23.5px;
}

.feed-page-top-banner-content {
  min-width: 1px;

  h3 {
    color: $text-1;
    font-size: 26px;
    line-height: 34px;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 23px;
    color: $gray-1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }

  span {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: $brand;
    margin-right: 22.5px;
  }
}

.feed-page-search-box {
  img {
    position: absolute;
    left: 20px;
    top: 14px;
    z-index: 1;
  }

  input {
    border-radius: 100px;
    padding-left: 50px;
    font-size: 16px;
    line-height: 21px;
    color: $text-3;

    &::placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      font-size: 16px;
      line-height: 21px;
      color: $text-3;
    }
  }
}

.feed--filters {
  .filter-wrapper {
    padding-top: 0;
  }
}

.feed--profile-stats {
  padding-right: 0px;
  padding-left: 0px;
}

.feed-page-search-box {
  margin-right: 0px !important;
}

.top-banner--right-column {
  margin-left: 20px;
}

.feed--dashboard {
  display: flex;
}

.feed-feed {
  padding-right: 15px;
}

.feed-page-top-banner {
  margin: 10px 0 20px 0;
}

.feed--dashboard {
  margin-top: 10px;
  margin-bottom: 20px;
}

.feed-divider {
  margin-top: -10px;
}
.feed-subselector--selected-option,
.feed-subselector--button {
  font-weight: 600;
  line-height: 26px;
  font-size: 16px;
}

.feed-subselector--button {
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 2px;
}

.feed--ask-question {
  margin-bottom: 5px;
}

@media (max-width: 767px) {
  .filter-btn-group {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 30px;
  }
  .filter--button {
    min-width: 193px;
  }
  .btn__load-more {
    width: 100%;
  }
  .feed-divider {
    display: none;
  }
  .feed--dashboard,
  .feed--footer,
  .feed--filters {
    &.open {
      display: none;
    }
  }
  .feed--profile-stats,
  .feed-right-container,
  .top-banner--right-column {
    display: none;
  }
  .feed--feed {
    padding-right: 0;
    padding-left: 0;
  }
  .feed-dashboard-cell .article-img img {
    height: 60px !important;
    width: 60px !important;
  }
}

@media (min-height: 768px) {
  .feed--sticky-content {
    position: sticky;
    top: 20px;

    &__inner {
      max-height: calc(100vh - 120px);
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 0px;
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .feed-dashboard-cell .article-img img {
    height: 40px;
    width: 40px;
  }
}

@media (min-width: 1201px) and (max-width: 1300px) {
  .feed-dashboard-cell .article-img img {
    height: 50px;
    width: 50px;
  }
}

@media (min-width: 1200px) {
  .feed--profile-stats {
    max-width: 300px;
  }
  .feed--feed {
    width: calc(100% - 550px) !important;
    flex: unset;
    max-width: unset;
  }
}

@media (min-width: 768px) {
  .feed--feed {
    width: calc(75% - 250px);
    flex: unset;
    max-width: unset;
  }
}

// Post onboarding button
.post-onboarding-btn {
  transform: translate(30px, -12px);
  width: 150px;
  font-size: 16px;
}

.build-your-network {
  label.custom-checkbox {
    align-items: flex-start !important;

    & > span:first-child {
      position: relative;
      top: 2px;
    }

    & > span.custom-checkbox-label {
      margin-left: 9px !important;
    }
  }
}

// remove engagement-buttons--items border-bottom in feed page
.feed--wrapper {
  .engagement-buttons--items {
    border-bottom: none;
  }
}

// Featured stacks
.featured-stacks {
  padding: 0 10px;

  &-item {
    width: 100%;
    min-width: 165px;
    max-width: 33.3333%;
    flex: 1;

    @media screen and (max-width: 1399px) {
      max-width: 50%;
    }

    @media screen and (max-width: 1199px) {
      max-width: 100%;
    }

    @media screen and (max-width: 1199px) {
      max-width: 100%;
    }

    .user-img {
      img {
        width: 60px;
        height: 60px;
        box-sizing: border-box;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .user-name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #232e34;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 4px;
    }

    .user-role {
      color: #9597ae;
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom: 11.5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .view-stack-btn {
      border-radius: 20px;
      padding: 5px 20px;
      font-weight: 700;
    }
  }

  .carousel {
    &-control-prev {
      left: -30px;
      width: initial;
    }

    &-control-next {
      right: -30px;
      width: initial;
    }
  }
}

.cta-message {
  align-items: center;
  margin: 0 auto;
  font-size: 18px;
  color: $text-1;

  p {
    span {
      color: $blue;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    p {
      margin-bottom: 20px;
    }
  }
}

@import "../../../../sass/globals.scss";

.banner-app {
  position: relative;
  height: 180px;
  background-color: #ffffff;
  border: 2px solid #cccccc;
  margin-top: $margin-top-header;
}

.btn-white,
.btn-white:hover,
.btn-white:focus,
.btn-white:active {
  background-color: #ffffff;
  color: $blue;
  border-color: $blue;
  border-width: 1px;
  font-weight: 600;
  min-width: 120px;
  padding: 6px 12px;
  border-radius: 100px;
  box-shadow: none;
}

.btn-blue,
.btn-blue:focus,
.btn-blue:active,
.btn-blue:hover {
  background-color: $brand;
  color: $white;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  border-radius: 100px;
  box-shadow: none;
}

a.a-blue {
  color: $brand;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
}

.btn-wrapper {
  position: absolute;
  top: 19px;
  right: 18px;
}

.banner-logo-wrapper {
  position: absolute;
  left: 35px;
  top: 6px;
  max-width: 160px;
  /* background-color: #fff; */
  padding: 2px;
  background-color: rgba(243, 246, 248, 0.94);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1.5px 3px 0px inset,
    rgba(0, 0, 0, 0.15) 0px 1.5px 3px 0px;
  margin: auto;
}

.banner-logo-wrapper img {
  width: 150px;
  height: 150px;
  border: 3px solid white;
}

.banner-summary {
  position: absolute;
  left: 210px;
  top: 60px;
}

.banner-header {
  color: black;
  font-size: 32px;
  font-weight: 500;
}

@import "../../../sass/globals.scss";

.onboarding {
  &--radio {
    margin-right: 40px;
  }
  &--input,
  &--text-area {
    margin-bottom: 30px;
    padding-left: 20px;
  }
  &--input {
    border-radius: 28px;
  }
  &--select {
    padding-left: 20px;
    border-radius: 28px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;

    option {
      display: block;
      width: 100%;
      margin-left: 10px;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 2;
      color: #212529;
    }
  }
  &--text-area {
    border-radius: 8px;
  }
  &--button {
    border-radius: 80px;
    background: $brand;
    min-width: 160px;
    padding: 10px 18x;
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;

    &-welcome {
      margin-top: 24px !important;
    }
  }
  &--form {
    background: $white;
    box-shadow: 0px 0px 4px rgba(21, 62, 102, 0.1);
    border-radius: $border-radius;
    padding: 32px 60px 50px 50px;
  }
}

@media (max-width: 768px) {
  .onboarding--form {
    padding: 35px 17px;
  }
  .onboarding--input {
    border-radius: 8px;
    margin-bottom: 8px;
  }
  .onboarding--select {
    margin-bottom: 8px;
  }
  .onboarding--button-wrapper {
    justify-content: center !important;
    margin-bottom: 50px;
  }
  .onboarding--bio {
    display: none;
  }
  .form-control {
    font-size: 15px;
  }
}

.no-solicitation {
  font-size: 26px;
  line-height: 34px;
  color: $text-1;
  margin-top: 24px;

  @media (max-width: 768px) {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    line-height: 1.3;
  }
}

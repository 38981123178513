@import "../../../../sass/variables";

.footer {
  min-height: 60px;
}

.footer__left {
  display: flex;
  align-items: flex-end;
  white-space: nowrap;
}

.footer__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.footer__menu a {
  font-size: 12px;
  margin-left: 20px;
  font-weight: 500;
  color: #828282 !important;
  text-decoration: none;
  white-space: nowrap;
}

.footer__menu a:hover {
  color: #0056b3;
}

.footer__logo:hover,
.footer__logo {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 700;
  color: #2962ff;
  text-decoration: none;
  line-height: 24px;
}

.footer__logo span {
  font-size: 26px;
  font-weight: 500;
}

.footer__copyright {
  font-size: 13px;
  color: #828282;
  font-weight: 500;
  margin-left: 5px;
}

@media (max-width: 767px) {
  .footer__menu {
    flex-wrap: wrap;
    justify-content: center;

    a {
      margin: 0 10px;
    }
  }
  .footer__left {
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    margin-bottom: 40px;
  }
}

/* home footer */
.home-footer {
  background: #2e3469;
  padding: 20px 0;

  .footer {
    &__copyright {
      color: $text-3;
    }

    &__social_icons {
      & > a {
        padding: 0 12px;
      }
    }

    &__left {
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
      }
    }

    &__menu {
      a {
        color: white !important;
        font-size: 14px;
        line-height: 27px;
        font-weight: 400;
        padding: 0 15px;
      }

      @media screen and (max-width: 991px) {
        & a {
          padding: 0;
          margin-left: 10px;
        }
      }

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;

        & li {
          padding-bottom: 10px;
          padding-top: 10px;
        }
      }
    }
  }
}

@import "../../../sass/globals.scss";

.layout {
  position: relative;

  &--header {
    height: 184px;
    display: flex;
    &-background {
      background: $white;
      position: absolute;
      left: 0;
      height: 184px;
      width: 100%;
    }
    &-logo {
      color: $brand;
      align-self: center;
    }
  }
  &--header-welcome {
    height: 100px;

    .layout--header-background {
      height: 100px;
    }
  }
  &--title {
    color: $text-1;
    font-size: 48px;
    line-height: 1.2;
    text-align: left;
    margin-top: 45px;
    max-width: 1300px;
  }
  &--subtitle {
    color: $text-2;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    margin-top: 20px;
  }
  &--children {
    margin-top: 42px;
    margin-bottom: 30px;
  }
}

.onboarding-title {
  color: $text-1;
  font-weight: 500;
  font-size: 56px;
  line-height: 73px;
  max-width: 900px;
}

@media (min-width: 1000px) {
  .layout--header-logo {
    padding-left: 50px;
  }
}

@media (max-width: 768px) {
  .layout--title {
    margin-top: 15px;
    font-size: 40px;
  }
  .layout--children {
    margin: 15px 0;
  }
}

@media (max-width: 500px) {
  .layout--title {
    font-size: 26px;
    text-align: center !important;
    line-height: 1.2;
  }
  .layout--subtitle {
    font-size: 18px;
    text-align: center;
    line-height: 1.3;
    margin-top: 10px;
  }
}

// Welcome Onboarding Component
.layout--children {
  .home--share {
    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto;
      width: 364px;
      max-width: 364px;
      padding-bottom: 50px;

      @media screen and (max-width: 440px) {
        width: 290px;
        max-width: 290px;
      }

      &-image {
        width: 84px;
        height: 84px;

        @media screen and (max-width: 440px) {
          width: 64px;
          height: 64px;
        }
      }

      &-title {
        font-size: 26px;
        line-height: 30px;
        margin-bottom: 27px;
        color: $text-1;

        @media screen and (max-width: 440px) {
          font-size: 20px;
          line-height: 25px;
        }
      }

      &-subtitle {
        padding: 0 8px;
        font-size: 18px;
        line-height: 25px;
        color: $text-2;
      }
    }
  }
}

@media (max-width: 359px) {
  .layout--children {
    .home--share-items-wrapper .home--share-item {
      width: 100% !important;
    }
  }
}

.progress-bar-header {
  @media (max-width: 700px) {
    display: none !important;
  }
}

.welcome-featured-members {
  max-width: 1074px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    max-width: 874px;
  }

  @media screen and (max-width: 1200px) {
    max-width: 740px;
  }

  @media screen and (max-width: 992px) {
    max-width: 560px;
  }

  @media screen and (max-width: 856px) {
    max-width: 364px;

    .featured-members-item {
      flex: 1 1;
    }
  }

  @media screen and (max-width: 500px) {
    max-width: 300px;
  }
}

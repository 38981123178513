@import "../../../../sass/globals.scss";

.fadeElementIn {
  opacity: 1;
  animation: fade-element-in 200ms ease;
  z-index: 100;
}

.fadeAndSlideElementIn {
  opacity: 1;
  animation: fade-slide-element-in 1000ms ease;
  transition-delay: 0ms;
}

.fadeAndSlideElementInFast {
  opacity: 1;
  animation: fade-slide-element-in 400ms ease;
  transition-delay: 0ms;
}

@keyframes fade-element-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-slide-element-in {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.nav-app {
  justify-content: flex-end;
  margin-left: 45px;
}

.navbar-collapse {
  justify-content: space-between;
}

.navbar {
  background-color: $white;
  min-height: 80px;
  padding: 0;
  width: 100%;
}

.navbar-brand:focus,
.navbar-brand:hover,
.navbar-brand {
  color: $white;
  font-weight: bold;
  font-size: 26px;
  font-family: "Montserrat";
  width: 100px;
  position: relative;
  border-radius: 11px;
}

.navbar-brand--item {
  position: absolute;
  left: 13px;
  top: 3px;
}

.navbar-brand span {
  font-weight: normal;
  font-size: 33px;
}

.navbar-nav .nav-link {
  align-items: center;
  color: $text-2 !important;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 500;
  margin-right: 25px;
  position: relative;
  text-align: center;
  transition: color 0.2s ease-in-out;
  white-space: nowrap;
  width: 55px;
}

.nav-link img {
  margin-bottom: 10px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
}

.navbar-dark .navbar-nav .nav-link img {
  height: 24px;
  width: 24px;
  opacity: 0.25;
}

.nav-link.active {
  color: $brand !important;
  background: $white;
  font-weight: 700;
  line-height: 16px;
  border-bottom: 2px solid $brand;

  span.inbox--number {
    top: 3px;
  }

  span.notifications--number {
    top: 5px;
  }
}

.nav-link.active img {
  opacity: 1 !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: $white;
}

.nav-link:hover img {
  opacity: 1 !important;
}

.dropdown.active img {
  opacity: 1 !important;
}

#basic-nav-dropdown {
  display: block;
}

.search-form {
  max-width: 354px;
  width: 100%;
  position: relative;
}

.search-btn {
  position: absolute;
  margin-left: 5px;
  top: 3px;
  padding: 0;
  border: 0;
}

.search-btn img:focus,
.search-btn:focus {
  outline: 0;
  box-shadow: none;
  border: none;
}

.form-inline .form-control {
  width: 100%;
  padding-left: 33px;
  font-size: 14px;
  font-weight: 400;
  background-color: #e1e9ee;
  border-radius: 2px;
  height: 34px;
  padding: 0 8px 0 28px;
  transition: 0.2s ease;
}

.form-inline .form-control:focus {
  color: #495057;
  background-color: $white;
  border-color: $white;
  outline: 0;
  box-shadow: none;
}

.navbar-dark .navbar-nav .nav-link .message-icon {
  height: 28px;
  margin-bottom: 5px;
  transform: translateY(3px);
}

.profile-dropdown {
  color: $text-2 !important;
  font-size: 13px;
  font-weight: 500;
}

.header-search {
  width: 39%;
  font-size: 13px;
  font-weight: 399;
  border-radius: 1px;
  height: 44px;
  transition: -1.2s ease;
}

.header-search > div > .rbt-input-main {
  padding-left: 50px;
  padding-right: 7px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 21px;
}

.search-btn {
  position: absolute;
  top: 8px;
  left: 17px;
}

.inbox {
  &--rectangle {
    position: absolute;
    top: -1;
    right: -1;
  }
  &--number {
    color: $white;
    position: absolute;
    top: 0px;
    right: 7px;
  }
}

.notifications {
  &--wrapper {
    position: absolute;
    top: 0;
    left: 28px;
    background-color: $red;
    min-width: 28px;
    border-radius: 20px;
    border: 3px solid $white;
    padding: 0 4px;
    color: $white;
  }
}

.dropdown-toggle {
  &::after {
    border-top: -1.5em solid;
    border-right: -1.5em solid transparent;
    border-left: -1.5em solid transparent;
    top: 29%;
    right: -5px;
    position: absolute;
  }
}

.header {
  background-color: $white;
  &--logo {
    position: relative;
  }
  &--tag {
    max-height: 19px;
    margin-left: 7px;
    padding: 4px 8px;
    border-radius: 9px;
    background-color: $gray-5;
    color: $text-2;
    font-size: 9px !important;
    font-weight: 599 !important;
    line-height: 9px;
    position: absolute;
    top: 9px;
    width: 36px;
  }
}

.navbar-dropdown--wrapper,
.navbar--mobile-search {
  display: none;
}

@media (max-width: 1199px) {
  .search-form {
    max-width: 300px;
  }
}

@media (max-width: 991px) {
  .navbar-brand {
    margin-right: 18px;
  }
  .search-form {
    max-width: 180px;
  }
  .navbar-nav .nav-link {
    width: 55px;
    padding: 0;
    margin-right: 10px;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 0;
  }
  .form-inline .form-control {
    font-size: 14px;
  }
  .navbar-dark .navbar-nav .nav-link.active::after,
  .navbar-dark .navbar-nav .nav-link.active:hover::after {
    content: "";
    width: 55px;
  }
}

@media (max-width: 767px) {
  .height-100 {
    padding: 0;
  }
  .navbar-nav {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .navbar-nav .nav-link {
    width: 30px;
    margin-right: 5px;
  }
  .search-form {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .navbar-dark .navbar-nav .nav-link.active::after,
  .navbar-dark .navbar-nav .nav-link.active:hover::after {
    content: "";
  }

  .navbar-dark .navbar-nav .nav-link img {
    height: 16px;
    width: 16px;
    opacity: 0.25;
  }
  .navbar {
    justify-content: flex-start;
    padding: 11.5px 0;
  }
  .header-search {
    width: 100% !important;
  }
  .navbar-toggler {
    background-image: url("./svgs/menu.svg");
    background-repeat: no-repeat;
    background-position: center;
    margin-left: -16px;
    position: relative;
    top: 2px;

    &.open {
      background-image: url("./svgs/cross.svg");
      top: 7px;
    }
  }
  .navbar-dropdown {
    border-radius: 39px;
    border: 1px solid $gray-3;
    position: absolute;
    right: 0px;
    height: 42px;
    text-align: left !important;
    padding-left: 0px;
    a {
      border-radius: 39px;
      color: $brand;
      margin-right: 10px;
      padding: 8px 20px !important;
      &:after {
        right: 10px;
        top: 19px;
        left: initial;
        padding: 0px;
      }
    }
    .dropdown-menu.show {
      right: 0px;
      left: unset;
      margin-top: 5px !important;
      background: #ffffff;
      border: 1px solid #e0e0e7;
      box-sizing: border-box;
      box-shadow: 2px 7px 19px #0000001a;
      border-radius: 6px;
      padding: 15px 12px;

      a {
        background: #ffffff;
        border: 1px solid #d3e0f5;
        box-sizing: border-box;
        border-radius: 39px;
        text-align: center;

        & {
          margin-bottom: 10px;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .navbar-dropdown.post-dropdown a::after {
    right: 2px;
  }
  .navbar-nav {
    flex-direction: column;
  }
  .nav-app {
    opacity: 0;
    height: 0;
    transition: opacity 0.2s ease-in-out;
    transition: height 0.2s ease-in-out;
    margin-left: 0;

    &.show {
      opacity: 1 !important;
      height: auto;
    }
  }
  .nav-app .nav-link {
    align-items: center !important;
    background: $white;
    color: $text-2;
    display: flex;
    flex-direction: row !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    height: 80px;
    padding-left: 15px !important;
    position: relative;
    text-align: center;
    width: 100% !important;

    img {
      margin-right: 20px;
      margin-top: 10px;
    }
    &.active {
      border-bottom: none;
    }
  }
  .navbar-collapse {
    margin-top: 20px;
  }
  .header--separator {
    width: calc(95% - 55px);
    height: 1px;
    background: #c6d3de;
    position: absolute;
    bottom: 0;
    left: 55px;
  }
  .header--logo {
    &.open {
      position: absolute;
      right: 40px;
    }
  }
  #basic-nav-dropdown {
    position: relative;
    // height: 80px;
    div {
      display: flex;
      height: 100%;
      flex-direction: row;
      align-items: center;
    }
  }
  .dropdown-toggle {
    &::after {
      left: 152px;
      position: absolute;
      right: unset;
      top: 37px;
    }
  }
  .navbar-nav .dropdown-menu .basic-nav-dropdown {
    position: absolute;
    left: 0 !important;
  }
  .header {
    margin-bottom: 10px;
    &.open {
      margin-bottom: 600px;
    }
  }
  .navbar-dropdown--wrapper,
  .navbar--mobile-search {
    display: initial;
    &.open {
      display: none;
    }
  }
  .navbar--search {
    display: none;
  }
  .profile-dropdown {
    font-size: 16px;
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  .header-search {
    margin-top: 20px;
  }

  .navbar-dropdown {
    top: 20px;
  }

  .header {
    &--tag {
      display: none;
    }
  }
}

@import "../../../../sass/globals.scss";

.featured-members {
  flex-wrap: wrap;

  &-item {
    width: 100%;
    min-width: 165px;
    flex: 1;
    padding: 10px;

    @media screen and (max-width: 1199px) {
      flex: 0 0 50%;
    }

    @media screen and (max-width: 576px) {
      flex: 0 0 100%;
    }

    .custom-card-body {
      padding: 18px 20px;
    }

    .color-0 {
      border: none;
      background-color: rgba(255, 92, 41, 0.15);
    }

    .color-1 {
      border: none;
      background-color: rgba(41, 204, 255, 0.15);
    }

    .color-2 {
      border: none;
      background-color: rgba(71, 255, 41, 0.15);
    }

    .color-3 {
      border: none;
      background-color: rgba(255, 195, 41, 0.15);
    }

    .user-img {
      img {
        width: 75px;
        height: 75px;
        box-sizing: border-box;
        background-clip: content-box;
        border: 2px solid transparent;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .user-name {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $text-1;
      font-size: 18px;
      line-height: 22px;
      margin-top: 17px;
    }

    .user-role {
      color: $text-2;
      font-size: 15px;
      line-height: 17px;
      margin-top: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

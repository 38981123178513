@import "../../../sass/globals.scss";

.question-answer-section-wrapper {
  justify-content: space-between;
  min-height: 800px;
}

.question-answer-section {
  .article-text {
    font-size: 16px;
  }
  .article--content .article-text li,
  .article--content .article-text p {
    margin-bottom: 16.5px;
    line-height: 22.5px;
  }
  margin-top: 20px;
}

.question-answer-section-replies {
  color: $text-1;
  margin-top: 20px;
  padding: 10px 0;
  font-weight: 700;
  font-size: 16px;
}

.question-related-section {
  margin-top: 22px;
  margin-right: 15px;
}

.question-related-section-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.question-related-section-item {
  color: black;
  &-text {
    padding: 8px 0;
  }
}

.question-your-answer-section {
  border: 1px solid #cccccc;
  padding: 10px;
  background: white;
  font-size: 20px;
}

.question-article-comment {
  margin-left: 80px;
}

.article-engagement-buttons-with-margin {
  display: flex;
  margin-left: 50px;
}

.question-comments-section {
  margin-left: 0px;
}

.question-activity-indicator {
  margin: 50%;
}

.article__type-comment {
  background: $gray-6b;
  margin-top: 8px;
  margin-bottom: 0px;
}

.question-answer-section-wrapper {
  margin-top: $margin-top-header;
}

.question-answers--item {
  margin-bottom: 20px;
}

.question-your-answer {
  background: $white;
  border-radius: 20px;
  border: $border-card;
  padding-top: 10px;

  &-text {
    padding-left: 30px;
    color: $text-1;
    font-weight: 500;
  }
}

.article-wrapper-content-detail {
  .article-title {
    font-size: 24px;
    font-weight: 600;
  }
}

@media (max-width: 991px) {
  .article-wrapper-content-detail {
    .article-badge {
      position: relative;
      right: initial;
      top: initial;

      .edit-content-btn {
        position: relative;
        right: initial;
        top: initial;
      }
    }
  }
}

@media (max-width: 768px) {
  .content--error,
  .content--content,
  .content--footer,
  .question-answer-section {
    &.open {
      display: none;
    }
  }
  .question-related-section {
    margin-right: 0px;
  }
  .question-answer-section {
    margin: 0;
    .article-wrapper {
      margin: 10px 0;
    }
  }
  .question-answer-section-replies {
    margin: 10px;
  }
  .question-comments-section {
    margin-left: 0px;
  }
}

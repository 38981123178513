.gallery-caption {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  font-size: 14px;
  text-align: center;
  color: #666;
}

@import "../../../sass/globals.scss";

.section-break {
  float: left;
  margin-right: 20px;
  margin-top: 1px;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.sort {
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.sort .custom-select {
  padding: 5px 20px 5px 10px;
  width: initial;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

.sort .select-wrapper {
  position: relative;
}

.sort .select-wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.sort .custom-select:focus {
  border: none;
  box-shadow: none;
}

.sort span {
  color: #888888;
}

.article-label {
  color: #222;
  font-weight: 600;
}

.connected-label {
  background-color: #fff;
  color: $brand;
  border-color: $brand;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  margin-top: -5px;
  display: inline-block;
}

.connect-button {
  background-color: #fff;
  color: $brand;
  border-color: $brand;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  margin-top: 0 !important;
  border-radius: 100px;
}

.connect-button:hover {
  background-color: rgba(152, 216, 244, 0.25);
  color: $brand;
  border-color: $brand;
  box-shadow: inset 0 0 0 1px #a0c4d9;
}

.follow-vendors {
  font-size: 20px;
}

.network--badge {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .vendors--popular-topics {
    display: none;
  }
  .vendors--simple-top-banner,
  .vendors--footer,
  .vendors--feed-item,
  .vendors--filters,
  .vendors--feed {
    &.open {
      display: none;
    }
  }

  .vendors--feed-item.mt-3 {
    margin-top: 4px !important;
  }

  .vendors--filters {
    align-items: center;
    justify-content: space-between;

    .filter-dropdown--wrapper {
      .filter-dropdown {
        margin-left: 0;
      }
    }

    .filter-add-vendor-btn {
      flex-grow: unset !important;
      button {
        margin: 0 !important;
      }
    }
  }
}

@media (max-width: 500px) {
  .vendors--filters {
    flex-direction: column;
    align-items: flex-start;

    .filter-add-vendor-btn {
      margin-left: 0;

      button {
        width: 212px;
      }
    }
  }
}

.filter-add-vendor-btn {
  width: 212px;

  button {
    font-size: 16px;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  @media (max-width: 576px) {
    flex-grow: unset !important;
  }

  @media (max-width: 500px) {
    &.filter-btn-group {
      padding: 0;
    }
  }
}

.desktop-add-vendor-button {
  display: block;

  @media (max-width: 768px) {
    &.desktop-add-vendor-button-network {
      display: block;
      margin-left: 0;
    }
  }
}

// vendor list
.vendor-list-divider {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: relative;
  text-align: right;
  padding-top: 40px;
  padding-bottom: 14px;
  font-size: 15px;

  &::after {
    content: "";
    width: 100%;
    border-bottom: solid 1px #c9ced1;
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
  }

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: none;

    &.mobile {
      display: block;
      padding-top: 10px;

      &::after {
        width: calc(100% - 140px);
      }
    }
  }
}

.vendors--feed--wrapper {
  .vendors--popular-topics {
    .popular-topics {
      position: sticky;
      top: 20px;
    }
  }
}

.vendor-list-container {
  display: flex;

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }

  .vendor-list-info {
    width: 380px;
    margin-right: 20px;
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      width: auto;
      margin-right: 0;
      margin-bottom: 0;
    }

    h1 {
      color: $text-1;
      font-size: 32px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    p {
      color: $text-3;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 20px;

      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    button {
      display: none !important;

      @media screen and (max-width: 767px) {
        display: flex !important;
        opacity: 0.8;
        border-radius: 20px;
        padding: 8px 22px;
        margin-left: auto !important;
        margin-bottom: 20px !important;
      }
    }
  }

  .vendor-list-vendors {
    flex: 1;

    .row {
      & > div {
        padding-left: 6px;
        padding-right: 6px;

        @media screen and (max-width: 767px) {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }

    .vendor-article-text {
      font-size: 15px;
    }

    &-desktop {
      display: flex;
      .network-list-item {
        min-height: 332px;
        max-width: 330px;
        margin-bottom: 12px;
      }
    }

    &-mobile {
      display: none;
    }

    .article-body {
      > .d-flex {
        flex-direction: column;
        text-align: center;

        .article--image {
          margin-right: 0 !important;

          .article-img {
            margin-bottom: 4px;

            img {
              width: 60px;
              height: 60px;
            }

            @media screen and (max-width: 576px) {
              max-width: 100%;
              margin-bottom: 4px;

              img {
                width: 42px;
                height: 42px;
              }
            }
          }
        }

        .article-subheadlines {
          max-width: 100%;
        }

        .article-text {
          text-align: left;
        }
      }
    }

    @media screen and (max-width: 767px) {
      &-desktop {
        display: none;
      }

      &-mobile {
        display: block;
      }
    }
  }

  .vendor-card-container {
    min-height: 270px;
    @media screen and (max-width: 767px) {
      min-height: 210px;
    }
  }

  .vendor-card-connections {
    p {
      font-size: 15px;
      color: $text-3;
    }
  }

  .vendor-card-divider {
    height: 1px;
    background: $gray-4;
    margin-left: -20px;
    margin-right: -20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}

.vendor-detail-category {
  margin-bottom: 15px;

  &-content {
    flex-wrap: wrap;
  }

  &--name {
    font-size: 24px;
    font-weight: 500;
    color: $brand;
  }

  &--description {
    color: $text-3;

    &.mobile {
      display: none;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start !important;

    &--description {
      display: none;

      &.mobile {
        display: block;
      }
    }
  }
}

.vendors--popular-topics-customhead {
  a {
    color: $brand;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }

  h2 {
    margin-top: 8px;
    margin-bottom: 4px;
    font-weight: 500;
  }

  p {
    color: $text-3;
    font-weight: 400;
    font-size: 15px;
  }
}

.no-vendor-list-header {
  color: $text-1;
  font-size: 18px;
  font-weight: 400;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

// BlockerText
.add-vendor-blocker-wrapper.vendor-list-page-wrapper {
  .add-vendor-blocker-opacity {
    width: calc(100% - 391px);
    left: 391px;

    @media screen and (max-width: 991px) {
      left: 0;
      width: 100%;
    }
  }

  .add-vendor-blocker-modal {
    right: calc((100% - 391px) / 2 - 200px);
    left: inherit;
    padding: 28px 28px;

    button {
      padding: 5px 30px !important;
    }

    @media screen and (max-width: 991px) {
      right: calc(50% - 200px);
    }

    @media screen and (max-width: 476px) {
      width: calc(100% - 34px);
      left: 17px;
    }
  }
}
.add-vendor-blocker-wrapper.vendor-detail-page-wrapper {
  .add-vendor-blocker-opacity {
    @media screen and (max-width: 767px) {
      width: 100%;
      left: 0;
    }
  }
}
.add-vendor-blocker-wrapper {
  .filter-btn-group {
    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.add-vendor-btn {
  white-space: nowrap;
  cursor: pointer;
  color: #2962ff !important;
  font-weight: 500;
  border: 1px solid rgb(41, 98, 255);
  padding: 8px 16px;
  border-radius: 20px;
  font-size: 16px;
  line-height: initial;
  display: block;

  @media screen and (max-width: 767px) {
    margin-top: 16px !important;
  }
}

.add-mystack-btn {
  white-space: nowrap;
  cursor: pointer;
  color: #2962ff !important;
  padding-top: 10px;
  padding-right: 8px;
  display: block;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.vendor-list-button-wrapper {
  display: flex;
  justify-content: flex-end;

  .filter--button-active {
    opacity: 0.8;
    border-radius: 20px;
    padding: 8px 22px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 16px;

    &:last-child {
      margin-bottom: 40px;
    }
  }
}

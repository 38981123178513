@import "../../../../sass/globals.scss";

.featured-content {
  .custom-card {
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 20px;
    }
  }

  &.multi-content {
    .custom-card:not(:last-child) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: 0;
    }

    .custom-card:not(:first-child) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }

  &-list {
    display: flex;
    align-items: baseline;
    padding-bottom: 14px;

    & + & {
      border-top: 1px solid $gray-5;
      padding-top: 14px;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    &__marker {
      width: 7px;
      height: 7px;
      background-color: $brand;
      border-radius: 50%;
      margin-right: 10px;
    }

    &-item {
      flex: 1;
      display: flex;

      &__image {
        width: 22px;
        height: 28px;
        margin-right: 11px;
        margin-top: 4px;
      }

      &__title {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: $text-1;
      }

      &__subtitle {
        color: $text-1;
        font-size: 15px;
        font-weight: 400;
        line-height: 1.2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      &__markdown {
        margin: 6px 0;
        color: $text-1;
        font-size: 15px;
        line-height: 21px;

        a {
          background-color: #e7f4ff;
        }
      }

      &__link {
        display: block;
        padding: 0px 0;
      }
    }

    .avatar-thumb {
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
}

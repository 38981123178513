@import "../../../../sass/globals.scss";

.comment-wrapper {
  display: flex;
  padding-bottom: 20px;
}

.comment-input {
  background: $gray-6b;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 35px;
  resize: none;
  width: 100%;
  height: 48px !important;
}

.comment-button {
  opacity: 0;
  border-radius: 20px;
  height: 46px;
}

.comment-button.show {
  opacity: 1;
  justify-self: flex-end;
}
.comment-image {
  border-radius: 50%;
  height: 40px;
  margin-right: 5px;
  width: 40px;
}

.comment-article-wrapper {
  background: "#ffffff";
  background-color: #ffffff;
  margin: 0 0px 8px;
}

.article-only-comment {
  border-radius: 0 !important;
  box-shadow: none !important;
  padding-top: 20px;
}

.comment-wrapper {
  background-color: #ffffff;
  border-radius: 2px;
  padding: 20px;
  width: 100%;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.comment-wrapper-with-margin {
  padding-left: 60px;
  padding-top: 20px;
}

.comment-body {
  padding-left: 65px;
}

.comment-wrapper-header {
  padding: 15px 16px 0 15px;
  margin-top: 15px;
  display: flex;
}

.comment {
  &--image {
    margin: 0 10px;
    img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &--rich-editor {
    position: relative;
    width: 100%;

    .editor {
      min-height: 100px;
      margin-bottom: 0;
    }
  }

  &--buttons {
    display: flex;
    position: absolute;
    bottom: 3px;
    left: 180px;

    button {
      height: 34px;
      font-size: 14px;
      padding: 0 4px;
      width: unset;
      margin: 0;
    }
  }
}

.submit-button {
  position: absolute;
  right: 25px;
  width: auto;
  bottom: 25px;
  background: transparent;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
    border: 0 !important;
  }

  &.comment-submit-button {
    bottom: 29px;
    display: block;

    &.comment-submit-hide-button {
      display: none;
    }
  }
}

@media (max-width: 575px) {
  .comment {
    &--buttons {
      button:nth-child(1),
      button:nth-child(2) {
        display: none;
      }
    }
  }
}

@media (max-width: 991px) {
  .modal-section-body-text {
    display: none;
  }
}

@import "../../../../sass/globals.scss";

.popular-topics {
  position: sticky;
  top: 20px;

  .custom-card-body {
    max-height: calc(100vh - 200px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  &--wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &--content {
    padding: 10px 0px;
    margin-bottom: 60px;
  }

  &--content-item {
    cursor: pointer;
    display: inline-block;
    padding: 7px 0;

    &-link {
      padding: 10px 15px;
    }
  }

  &--footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-left: -20px;
    text-align: center;
    background-color: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &--divider {
    border-top: $border-card;
  }
  &--button {
    margin: 12px 0;
    > button {
      color: $brand;
    }
  }
}

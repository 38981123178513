@import "../../sass/globals.scss";

.Homepage {
  text-align: center;
}

.Homepage-logo {
  height: 40vmin;
  pointer-events: none;
}

.Homepage-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.img__homepage {
  width: 100%;
}

.Homepage-link {
  color: #61dafb;
}

.about__homepage {
  margin-top: -10px;
}

.about__homepage .row {
  border-bottom: 1px solid #dedede;
  padding: 30px 0;
}

.about__homepage .row:last-child {
  border-bottom: none;
}

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}

.hp__img {
  max-width: 862px;
  margin: 0 auto;
  mix-blend-mode: darken;
}

.hp__img > img {
  border-radius: 10px;
  margin: 20px;
  padding: 10px;
  box-sizing: border-box;
  box-shadow: 0 6px 12px -2px rgba(50, 50, 93, 0.25),
    0 3px 7px -3px rgba(0, 0, 0, 0.3);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.home {
  &--page {
    .container {
      @media screen and (max-width: 1199px) {
        max-width: initial;
      }
    }
  }
  &--header {
    background: $white;
    padding-left: 30px;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 80px;
    margin: 0;
    width: 100%;
    z-index: 1;

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-button {
    }
    &-logo {
      display: block;

      .nav__logo {
        font-size: initial;
      }
    }
  }
  &--title {
    color: $gray-500;
    font-family: "Poppins";
    font-size: 44px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 20px;
    padding-top: 25px;
    max-width: 600px;
    letter-spacing: 0.01em;
  }
  &--description {
    color: #40495c;
    font-size: 20px;
    line-height: 1.5;
    max-width: 500px;
    margin-bottom: 40px;
  }
  &--apply-button {
    button {
      padding: 10px 24px;
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;

      &:hover,
      &:focus {
        padding: 10px 24px;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
      }
    }
  }
  &--svg {
    width: 600px;
  }
  &--subtitle {
    margin-top: 16px;
    color: $text-2;
    text-align: center;
    font-size: 20px;
    line-height: 26px;
  }
  &--form {
    margin-top: 56px;
    padding: 60px;
    padding-top: 0;

    &-title,
    &-subtitle,
    &-green-text {
      text-align: center;
    }
    &-title {
      color: $text-1;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
    }
    &-subtitle {
      color: $text-2;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
    &-green-text {
      color: $success;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin-bottom: 6px;
    }
  }
  &--form-left {
    background: #e0e9ff;
    border-bottom-left-radius: 23px;
    border-top-left-radius: 23px;
    padding: 60px;
    height: 604px;
  }
  &--form-right {
    background: $white;
    border-bottom-right-radius: 23px;
    border-top-right-radius: 23px;
    padding: 60px;
    height: 604px;
    .home--form-subtitle {
      text-align: center;
      padding: 0 70px;
    }
  }
  &--form-linkedin {
    width: 250px;
  }
  &--form-eula {
    margin-top: 15px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    padding: 0 60px;
  }
  &--form-questions {
    font-size: 14px;
    margin-top: 20px;
    padding: 0 30px;
    text-align: center;
  }
  &--input {
    padding-left: 20px;
    border-radius: 23px;
    width: 100%;
  }
  &--label {
    color: $text-1;
  }
  &--leaders {
    background: $white;
    min-height: 180px;
    padding-top: 70px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;

    &-section {
      &-items-wrapper {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
      &-title {
        color: $text-1;
        font-size: 36px;
        font-family: "DM Sans", sans-serif;
        line-height: 1.3;
        font-weight: 500;
        text-align: center;
        max-width: 75%;
        margin: 0 auto;
        margin-bottom: 26px;
      }
      &-item {
        margin-bottom: 30px;
        min-width: 200px;
        text-align: center;
        img {
          height: 60px;
          object-fit: contain;
          width: 100px;
          padding-left: 5px;
          padding-right: 5px;
        }
      }
    }
  }

  &--share {
    padding: 80px 0 107px;
    background-color: #fafafa;

    @media screen and (max-width: 767px) {
      padding-bottom: 40px;
    }

    &-title {
      color: $text-1;
      font-size: 36px;
      font-family: "DM Sans", sans-serif;
      font-weight: 500;
      line-height: 1.3;
      text-align: center;
      max-width: 75%;
      margin: 0 auto;
      margin-bottom: 35px;
      padding: 0 15px;
    }
    &-items-wrapper {
      margin: 0 50px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    &-item {
      background: $white;
      border-radius: 10px;
      // height: 304px;
      width: 400px;
      max-width: 400px;
      padding: 48px 16px;
      margin: 24px;
      box-shadow: rgba(24, 45, 76, 0.1) 0px 5px 10px;

      &-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        //        padding: 50px 8px 50px;
        height: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        -webkit-box-align: center;
        align-items: center;
        text-align: center;
      }

      &-image {
        width: 64px;
        height: 64px;
        margin-bottom: 20px;
        // margin: 50px 121px 34px 121px;
      }
      &-title {
        color: $text-1;
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 14px;
        line-height: 1.3;
        text-align: center;
      }
      &-subtitle {
        font-size: 16px;
        line-height: 1.4;
        color: #53617d;
        text-align: center;
      }
    }
  }
}

.home--hero {
  padding-top: 40px;
  padding-bottom: 50px;
  padding-left: 30px;
  padding-right: 15px;
  background-color: #fafafa;
}

@media (max-width: 1200px) {
  .home {
    &--page {
      .container {
        @media screen and (max-width: 1199px) {
          max-width: initial;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
    &--title {
      font-size: 32px;
      line-height: 1.4;
      font-family: "Poppins";
    }
    &--form {
      padding: 0px;
    }
    &--form-left,
    &--form-right {
      padding: 20px 0;
    }
    &--leaders-section {
      padding: 0 20px;
      margin: 20px 0;
    }
    &--leaders-section-title {
      font-size: 28px;
    }
    &--share-title {
      font-size: 28px;
      margin-bottom: 40px;
    }
    &--share-items-wrapper {
      margin: 0;
    }
    &--share-item {
      margin: 20px 5px;
    }
  }
}

@media (max-width: 856px) {
  .home--share-items-wrapper {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .home {
    &--header-button {
      display: initial;
      button {
        background-color: $white;
        border-radius: 20px;
        border-color: $gray-3;
        color: $brand;
      }
    }
    &--hero {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    &--svg {
      margin-top: 20px;
    }
    &--title {
      font-weight: 600;
      font-size: 26px;
      line-height: 1.4;
    }
    &--description {
      font-size: 18px;
      margin-bottom: 32px;
    }
    &--subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 22px;
      margin: 10px 20px 20px 20px;
      text-align: center;
    }
    &--form {
      margin: 0 18px;
      padding-bottom: 0px;
      padding-top: 0;
    }
    &--form-left {
      height: unset;
      border-radius: 20px;
      margin-top: 10px;
      padding: 30px;
      height: unset;
    }
    &--form-right {
      display: none;
    }
    &--form-linkedin {
      width: 250px;
    }
    &--form-eula {
      margin-top: 15px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      padding: 0 10px;
    }
    &--form-questions {
      font-size: 14px;
      margin: 20px 0;
      padding: 0 10px;
      text-align: center;
    }
    &--input {
      padding-left: 20px;
      border-radius: 23px;
      width: 100%;
    }
    &--label {
      color: $text-1;
    }
    &--leaders {
      padding-top: 30px;

      &-section {
        background-color: unset;
        padding: 0;
        min-height: unset;
        margin: 0;

        &-title {
          font-size: 22px;
          line-height: 1.4;
          margin-bottom: 0;
          padding: 0;
          margin-bottom: 20px;
          max-width: 90%;
        }
        &-item {
          background-color: $white;
          border-radius: 20px;
          height: 104px;
          margin-bottom: 10px;
          padding: 0;
          min-width: 150px;

          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 37px;
            width: 100px;
            height: 30px;
          }
        }
      }
    }
    &--share {
      padding-top: 40px;
      &-title {
        font-size: 22px;
        line-height: 1.4;
        text-align: center;
        margin-bottom: 20px;
        max-width: 90%;
      }
      &-item {
        margin: 15px auto;
        width: 100%;
        &-subtitle {
          color: $text-3;
          font-weight: 400;
          text-align: center;
        }
        &-image {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .home--form-left {
    padding: 20px 0px;
    height: 520px;
  }
  .home--form-left .signup--form-apply {
    margin: 0 auto;
    width: 250px;
  }
  .home--form-right {
    padding: 20px 0px;
  }
  .home--leaders-section-item {
    width: 45%;
  }

  .home--share-items-wrapper {
    .home--share-item {
      width: 300px !important;
    }

    .home--share-item-wrapper {
      width: initial;
      padding: 30px 0;

      .home--share-item-image {
        width: 72px;
      }

      .home--share-item-title {
        font-size: 22px;
        line-height: 1.4;
        font-weight: 700;
      }

      .home--share-item-subtitle {
        padding: 0;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 400px) {
  .home--header {
    padding-left: 15px;
  }
  .home--hero {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home--leaders {
    padding-left: 15px;
    padding-right: 15px;
  }
  .home--share {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 359px) {
  .home--share-items-wrapper {
    .home--share-item {
      width: 280px !important;
    }
  }
}

@media (max-width: 500px) {
  .home--title {
    .mobile {
      display: block;
    }
    .desktop {
      display: none;
    }
  }
}

@media (min-width: 501px) {
  .home--title {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
}

@import "../../../sass/globals.scss";

.list-transition-enter {
  opacity: 0;
  transform: translateY(-50px);
}
.list-transition-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition: all 400ms;
}
.list-transition-exit {
  opacity: 1;
}
.list-transition-exit-active {
  opacity: 0;
  transition: opacity 400ms;
}

.onboarding {
  &--card {
    padding: 0;
    margin: 0;

    &-content {
      padding: 0;
    }
    .custom-card-body {
      padding: 0;
    }
    &-invite {
      padding: 1.5rem;
    }
  }
  &--pill {
    background: transparent;
    border: 1px solid $brand;
    color: $brand;
    border-radius: 22px !important;
    padding: 8px 37px;
    margin-right: 20px;
    margin-bottom: 30px !important;
    &:focus,
    &:hover,
    &:active,
    &.active {
      background: $brand !important;
    }
    &-wrapper {
      padding: 40px 50px;
    }
    &-head {
      padding: 40px 50px;
      padding-bottom: 0px;
    }
    &-mid {
      padding-left: 50px;
      padding-right: 50px;
      font-size: 18px;
      line-height: 1.1;
    }
    &-check {
      float: left;
      margin-right: 5px;
      padding-bottom: 5px;
    }
  }
  &--show-more {
    color: $brand;
    padding: 22px;
    width: 100%;
  }
  &--divider {
    border-top: 1px solid $gray-5;
    position: absolute;
    left: 15px;
    height: 1px;
    width: 97.4%;
    margin: 0;
  }
  &--done {
    background: $brand;
    border-radius: 23px;
    padding: 12px 29px;
    min-width: 140px;
    &:hover,
    &:active,
    &:focus {
      background: $brand;
    }
  }
  &--button {
    &-skip,
    &-skip:hover,
    &-skip:focus,
    &-skip:active {
      border-color: $gray-2;
      color: $gray-2;
      min-width: 160px;
    }
  }
  &--label {
    font-size: 26px;
    font-weight: 600;
    color: #232e34;
    line-height: 1.2;
    margin-bottom: 10px;
  }
}

@media (max-width: 768px) {
  .onboarding--pill {
    padding: 6px 12px;
    margin-bottom: 20px !important;
  }
  .onboarding--pill-wrapper {
    padding: 20px 20px;
  }
  .onboarding--pill-head {
    padding-left: 20px;
    padding-right: 20px;
    line-height: 1.2;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .onboarding--pill-mid {
    padding-left: 20px;
    padding-right: 20px;
  }
  .onboarding--done-wrapper {
    justify-content: center !important;
    margin-bottom: 30px;
  }
  .onboarding--card-content {
    padding: 0px;
  }
}

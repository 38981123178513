.carousel-section {
  max-width: 342px;
  margin: 0 auto;

  &-icon {
    margin-bottom: 40px;
  }

  &-title {
    color: white;
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    margin-bottom: 22px;
  }

  &-content {
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
}

.carousel-indicators {
  bottom: 40px;

  li {
    background: url("./icons/indicator.svg");
    background-repeat: no-repeat;
    width: 8px;
    height: 8px;
  }

  li.active {
    background: url("./icons/active-indicator.svg");
    background-repeat: no-repeat;
    width: 33px;
    height: 8px;
  }
}

.carousel-control-prev {
  left: -45px;
}

.carousel-control-next {
  right: -45px;
}

@import "../../../../sass/globals.scss";

.invite-btn {
  width: 150px;
  padding: 6px 12px;
  border-radius: 20px;
  font-weight: 700;
}

.invite-module-modal {
  max-width: 826px;

  .modal-header {
    padding: 24px;

    .modal-title {
      padding-left: 0;
    }
  }

  .modal-body {
    padding: 22px 24px;
  }
}

.invite-module {
  .modal-description {
    font-size: 24px;
    line-height: 32px;
    color: $text-2;
    margin-bottom: 14px;
  }

  .row {
    margin-bottom: 16px;
  }

  .textarea {
    background: #ffffff;
    border: 1px solid #d1d1dc;
    box-sizing: border-box;
    border-radius: 8px;
  }

  .invite-module-footer {
    padding: 22px 28px 20px;
    margin: 22px -24px 0;
    justify-content: flex-start;
    border-top: 1px solid rgba(0, 0, 0, 0.2);

    .form-label {
      color: $text-1;
      font-size: 18px;
      line-height: 23px;
      font-weight: bold;
    }

    p {
      margin-bottom: 16px;
    }

    input {
      background: #ffffff;
      border: 1px solid #d1d1dc;
      box-sizing: border-box;
      height: 45px;
      padding: 12px 18px;
      margin-right: 16px;
    }

    button {
      padding: 12px 25px;
      font-size: 15px;
      line-height: 21px;
      margin: 0;
    }

    .invite-link-wrapper {
      flex-direction: row;
      align-items: center;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: flex-end;

        input {
          margin-bottom: 8px;
          margin-right: 0px;
        }
      }
    }
  }

  .invite-module-input {
    background: #ffffff;
    border: 1px solid #d1d1dc;
    box-sizing: border-box;
    border-radius: 28px;
    height: 45px;
    padding: 12px 18px;
  }

  .form-label {
    color: $text-1;
    font-size: 18px;
    line-height: 23px;
    font-weight: bold;
  }

  .btn-groups {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      padding: 12px 30px;
      font-size: 16px;
      line-height: 21px;
      margin: 0;
    }
  }
}

.invite-module-input {
  height: 40px;
}

.invite-modal-title-img {
  width: 30px;
  float: left;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
}

@import "../../../../sass/globals.scss";

.vendor-category-modal {
  .modal-dialog {
    max-width: 1200px;
  }

  .close-modal {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 2.5rem;
    z-index: 1;
    cursor: pointer;
  }

  .modal-footer {
    button {
      min-width: 120px;
    }
  }

  .form-add-vendors {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.form-add-vendors {
  margin-left: -10px;
  margin-right: -10px;

  .row:last-child {
    margin-bottom: 0 !important;
  }

  .form-add-vendors--header {
    padding-bottom: 22px;
    padding-right: 40px;
    padding-left: 40px;
    border-bottom: 1px solid #d1d1dc;

    div {
      color: $text-1;
      font-size: 25px;
      line-height: 32.5px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  .form-add-vendors--content {
    padding: 30px 40px;

    div.col-md-4 {
      margin-bottom: 15px;
    }

    .skills-text-area {
      margin-top: -15px;
      padding: 10px;
      height: 45px;
      color: $text-1;
      overflow: hidden;
    }

    @media screen and (max-width: 767px) {
      padding: 10px 0;

      & > div {
        margin-bottom: 1rem !important;
      }

      .skills-text-area {
        margin-top: -10px;
      }

      .mytool-review {
        div.col-md-4 {
          margin-bottom: 0;
        }
      }
    }
  }

  .form-control {
    padding: 0;
    color: $text-3;
    border-radius: 10px;
    min-height: 45px;

    .rbt-input-wrapper {
      margin-bottom: 0 !important;
      margin-top: 0 !important;

      .rbt-token {
        height: 36px;
        background-color: #dfe7ff;
        color: $text-1;
        font-size: 15px;
        font-weight: 500;
        border-radius: 25px;
        margin: 4px;
        align-items: center;
        display: flex;
        margin-right: 5px;
      }

      input {
        margin: 10px 0 10px;
        padding-left: 10px !important;
        color: $text-1;
      }

      input::placeholder {
        color: $text-2;
      }
    }
  }

  .vendor-category-dropdown {
    position: relative;
    top: initial;
    margin-right: auto;
    background-color: #ffffff;

    border-radius: 10px;
    border: 1px solid #d1d1dc;
    right: 0px;
    height: 45px;
    text-align: left !important;
    padding-left: 0px;
    font-size: 18px;

    &.show {
      & > a {
        &:after {
          content: url("../icons/up_tick.svg");
        }
      }
    }

    & > a {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $text-2;
      font-weight: 500;
      padding: 8px 20px !important;
      display: block;

      &:after {
        position: absolute;
        right: 16px;
        top: 8px;
        left: initial;
        padding: 0px;
        content: url("../icons/down_tick.svg");
        width: 12px;
        height: 12px;
        border: none;
      }
    }

    div.dropdown-menu.show {
      max-height: 300px;
      overflow: auto;
      border-radius: 6px;
      width: 100%;

      &::-webkit-scrollbar {
        width: 0px;
      }

      a {
        white-space: break-spaces;
        word-break: break-all;

        &:hover {
          background-color: #e9efff;
        }

        .category-dropdown--name {
          font-weight: bold;
          font-size: 16px;
          line-height: 17px;
          color: $text-1;
        }

        .category-dropdown--description {
          color: $text-3;
          font-size: 13px;
          line-height: 14.5px;
        }

        @media screen and (max-width: 768px) {
          background: #ffffff;
          border: none;
          border-radius: 0;
          text-align: initial;
        }
      }
    }

    div.dropdown-menu a {
      border-radius: 0;
    }
  }

  .vendor-category--popular-tools {
    display: flex;
    flex-wrap: wrap;

    &--header {
      width: 100%;
      font-weight: 700;
      display: none;

      @media screen and (max-width: 767px) {
        display: block;
      }
    }
    &--tool {
      width: 44px;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      padding-top: 5px;
      padding-bottom: 5px;

      &--icon {
        width: 100%;
        height: 44px;
        border: 1px solid #dee2e6;
        border-radius: 12px;
        padding: 5px;
        margin-bottom: 4px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &--name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $text-2;
        font-size: 10px;
      }
    }
  }
}

.category-filter-text .form-control {
  border-radius: 1px !important;
}

.skills-text-area::placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.vendor--profile--textarea.form-control {
  padding: 6px 10px;
  height: 56px;
  color: $text-1;
  font-size: 14px;
  min-height: 34px;
  overflow: hidden;
}

.vendor--profile--textarea.form-control::placeholder {
  color: $text-3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

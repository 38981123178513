@import "../../../../sass/globals.scss";

.custom-checkbox {
  margin-bottom: 20px;
  cursor: pointer;
}

.custom-checkbox-icon {
  display: flex;
  flex: 1;
  background-color: $blue;
  border-radius: 5px;
  border: 2px solid $blue;
  height: 18px;
  width: 18px;
  padding: 2px;
}

.custom-checkbox-icon.disabled {
  background-color: #ddd;
  border: 2px solid #ddd;
}

.custom-checkbox-label {
  margin-left: 13px !important;
  user-select: none;
  color: $text-1;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.custom-checkbox-label.disabled {
  background-color: #ddd;
}

.custom-checkbox-label.checked {
  text-decoration: line-through;
  color: $gray-1;
}

@import "../../../sass/globals.scss";

.overview {
  background-color: #ffffff;
  border-left: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  padding: 30px 15px 0 15px;
}

.profile-about {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  padding: 10px 0 20px 0;
  border-radius: $border-radius;
  border: $border-card;

  &-wrapper {
    padding: 20px 20px 0;

    @media screen and (max-width: 767px) {
      padding: 10px 10px 0;
    }
  }

  &--title {
    padding: 28px 0 5px 32px;
    border-bottom: $border-card;
  }
  &--content {
    margin-bottom: 20px;

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }
  &--experience,
  &--open {
    color: $gray-2;
  }
  &--experience-title {
    font-weight: 700;
    color: $text-1;
  }
  &--open-content {
    color: $success;
  }
}

.profile-about h2 {
  font-size: 22px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.9);
  margin-bottom: 5px;
}

.about-table tr td {
  min-width: 200px;
  max-width: 400px;
  vertical-align: top;
}

.about-tag {
  font-weight: 500;
  color: #2962ff;
  cursor: pointer;
}

.profile--wrapper {
  margin-top: $margin-top-header;

  & > div {
    margin-bottom: 20px;
  }

  .profile--accept-follow {
    margin-bottom: 10px;

    .alert {
      margin-bottom: 0px;
    }
  }
  .profile--right-section,
  .profile--left-section {
    border-radius: $border-radius;
    border: $border-card;
    background-color: $white;
    height: 100%;
    min-height: 350px;
  }

  .profile--right-section {
    margin-bottom: 0;
  }
}

.profile-section-title {
  margin-left: 5px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold;
}

.profile-check {
  scale: 1.25;
  margin-top: -2px;
}

.overview--padding {
  padding: 30px;
}

.overview-summary {
  margin-left: 24px;
}

.overview-header {
  font-size: 26px;
  margin-top: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.overview-subheadline {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.overview-desc {
  max-width: 460px;
  color: #9a9a9a;
}

.overview-link-buttons {
  margin-top: 5px;
}

.overview-link-button {
  display: inline-block;
  width: 30px;
  margin-left: 15px;
}

.overview-link-img {
  width: 100%;
}

.overview p {
  margin-bottom: 10px;
}

.overview-followers {
  color: $brand;
  font-weight: 700;
}
.border {
  border: none !important;
  padding: 0 15px;
  border-bottom: 1px solid #cccccc !important;
}

.border:last-child {
  border-bottom: none !important;
}

.form-label {
  margin-top: 10px;
  font-weight: 600;
  margin-bottom: 2px;
}

.form-check-input {
  margin-top: 7px;
}

.subfilter {
  border-radius: 3px;
  border: none;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  outline: none;
  font-weight: 400;
  padding: 2px 6px;
  margin-right: 5px;
  margin-bottom: 2px;
  font-size: 16px;
  min-width: 50px;
  background-color: #f4f7f8;
  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;
  float: left;
  margin-top: 5px;
}

.subfilter:hover {
  background-color: #dbdbdb;
  color: #555;
  box-shadow: none;
  text-decoration: none;
}

.subfilter.active {
  background-color: #cbcbcb;
  color: #555;
  text-decoration: none;
}

.btn-white.edit-profile:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #006097;
}

.btn-white:active {
  background-color: #fff !important;
  color: $brand !important;
  border-color: $brand !important;
}

.btn-secondary {
  background-color: #ddd !important;
}

.profile-subfilters {
  padding-top: 5px;
  padding-bottom: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: 0 0 8px;
  display: inline-block;
  min-width: 100%;
  background-color: white;
}

.profile-subfilter {
  background-color: $gray-4;
  border-radius: 20px;
  border: none;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  outline: none;
  font-weight: 400;
  padding: 2px 10px;
  margin-right: 20px;
  margin-bottom: 2px;
  font-size: 16px;
  min-width: 50px;
  background-color: #f4f7f8;
  color: $text-3;
  white-space: pre-wrap;
  float: left;
  margin-top: 5px;
}

.profile-subfilter:hover {
  background-color: #dbdbdb;
  color: #555;
  box-shadow: none;
  text-decoration: none;
}

.profile-subfilter.active {
  background-color: #cbcbcb;
  color: #555;
  text-decoration: none;
}

.modal-primary-button {
  background-color: $brand;
  color: #fff;
  border-color: $brand;
}

.modal-primary-button:hover {
  background-color: $brand;
  color: #fff;
  box-shadow: inset 0 0 0 1px $brand;
}

.add-vendor-modal {
  width: 700px;
  max-width: 96%;
}

.add-vendor-modal .modal-header {
  padding: 12px;
}

.add-vendor-modal .modal-title {
  font-size: 28px;
  font-weight: bold;
}

.add-vendor-modal .modal-body {
  padding: 0rem 24px 1.5rem 24px;
  font-size: 18px;
}

.add-vendor-modal .form-label {
  font-weight: 400;
  margin-bottom: 5px;
  margin-top: 28px;
}

.add-vendor-modal .form-control {
  font-size: 15px;
  padding: 6px;
}

.add-vendor-modal-horizontal-rule {
  margin-top: 20px;
  margin-bottom: 0px;
  width: 100%;
  border: 1px solid lightgray;
  width: calc(100% + 48px);
  margin-left: -24px;
}

.no-reviews-header {
  text-align: center;
  font-weight: 600;
  padding-top: 25px;
}

.no-reviews-body {
  text-align: center;
  padding-bottom: 40px;
}

.no-review-button {
  margin-top: -5px;
  margin-left: 10px;
  line-height: 1.1;
}

.rbt-token {
  background-color: #e6f3ff;
  color: #555;
}

.profile-article-transition-enter {
  opacity: 0;
}
.profile-article-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.profile-article-transition-exit {
  opacity: 1;
}
.profile-article-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.rating-badge-large {
  width: 60px;
  height: 50px;
  background-color: white;
  color: $brand;
  font-size: 28px;
  font-weight: 700;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  border-radius: 2px;
  user-select: none;
}

.profile-connected-label {
  background-color: #fff;
  color: $blue;
  border-color: $blue;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  display: inline-block;
}

.profile-modal-hr {
  height: 1px;
  color: black;
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.desktop-delete {
  display: block;
}

.mobile-delete {
  display: none;
}

@media screen and (max-width: 767px) {
  .desktop-delete {
    display: none;
  }

  .mobile-delete {
    display: block;
    border: 1px solid;
    padding: 5px 20px;
    border-radius: 20px;
  }
}

.logo-wrapper {
  margin-top: -115px;
  margin-left: 24px;
  max-width: 160px;

  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 3px solid white;
  }

  @media screen and (max-width: 1199px) {
    margin-top: -70px;
  }
}

.left-section {
  &--image {
    height: 215px;
    width: 100%;
    object-fit: cover;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;

    @media screen and (max-width: 1199px) {
      height: 160px;
    }

    @media screen and (max-width: 991px) {
      height: 144px;
    }

    @media screen and (max-width: 991px) {
      height: 144px;
    }

    @media screen and (max-width: 767px) {
      height: 95px;
    }
  }
}
.right-section {
  &--intro {
    border-bottom: 1px solid #d8e5f1;
    padding: 15px 0 22px 24px;
    font-weight: 700;
  }
  &--item {
    margin-bottom: 20px;
    padding-left: 40px;
    position: relative;

    &-img {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 10px;
      top: 4px;
    }
  }
  &--email {
    color: $brand;
  }
  &--linkdin {
    > strong {
      color: $brand;
    }
  }
}

.btn-wrapper {
  position: absolute;
  top: 260px;
}

.edit-profile {
  margin-right: 10px;
  border-radius: 20px;
}

.edit-profile-more {
  border: none;
  padding: 0;
  margin: 10px;
  margin-right: 22px;
}

.profile {
  &--follow-button {
    width: 130px;
  }
  &--more-button {
    background-color: $white;
    border-color: $white;
    &:hover,
    &:active,
    &:focus {
      background-color: darken($white, 10);
      border-color: $white;
    }
  }
}

.expertise--input .rbt-input-multi {
  padding-left: 20px;
  border-radius: 4px;
  margin-bottom: 25px;
}

@media (max-width: 1200px) {
  .profile--popular-topics {
    display: none;
  }
  .profile--more-button {
    position: absolute;
    right: 0px;
  }
  .profile--left-section {
    padding-bottom: 100px !important;
    .btn-wrapper {
      top: unset;
      bottom: 40px;
      left: 24px;
    }
  }
  .profile--left-section,
  .profile--right-section {
    position: relative;
    height: unset;
  }
  .profile--filters {
    .filter-btn-group {
      padding-bottom: 0;
    }
  }
  .profile--right-section {
    min-height: 250px;
  }
  .profile--feed {
    margin-top: 10px;
  }
  .profile--feed {
    .popular-topics {
      display: none;
    }
  }
  .wrapper,
  .profile--feed,
  .profile--footer {
    &.open {
      display: none;
    }
  }
}

.vendor-profile-filter-and-addvendor {
  display: flex;

  @media (max-width: 767px) {
    justify-content: space-between;
    align-items: center;

    .filter-btn-group {
      justify-content: flex-end;
    }
  }

  @media (max-width: 576px) {
    .filter-btn-group {
      justify-content: flex-end;

      button {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
}

@media (max-width: 767px) {
  .profile--filters {
    margin: 20px 17px 0 17px !important;
  }
  .profile--feed {
    margin-top: 0;
  }
}

.vendor-detail-divider {
  width: 100%;
  height: 1px;
  background: $gray-5;
}

.profile--vendors--feed--wrapper .connections-wrapper-header {
  display: none;
}

.profile--vendors--feed--wrapper .avatar-content-text {
  white-space: normal;
  overflow: auto;
  text-overflow: initial;
}

.profile-page-blocker-wrapper {
  .add-vendor-blocker-opacity {
    width: 65.8%;
    left: calc(33.333333% + 10px);

    @media screen and (max-width: 768px) {
      width: 100%;
      left: 0;
    }
  }

  .add-vendor-blocker-modal {
    right: calc((65% - 400px) / 2);
    left: inherit;
    padding: 28px 28px;

    @media screen and (max-width: 768px) {
      left: calc(50% - 200px);
    }

    @media screen and (max-width: 476px) {
      left: 30px;
    }

    button {
      padding: 5px 30px !important;
    }
  }
}

.profile--article-item {
  .article-badge {
    margin-top: 10px;
    margin-right: 15px;
  }
}

// remove padding for delete modal title
.delete-post-modal {
  .modal-title {
    padding-left: 0;
  }
}

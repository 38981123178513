@import "../../sass/globals.scss";

.btn-linkedin {
  color: #fff;
  background-color: $brand;

  &:focus,
  &.focus {
    color: #fff;
    background-color: #005983;
  }

  &:hover {
    color: #fff;
    background-color: #386af5;
  }

  &:active,
  &.active {
    color: #fff;
    background-color: #386af5;
    border-color: rgba(0, 0, 0, 0.2);
    background-image: none;
  }

  &:active:hover,
  &.active:hover,
  &:active:focus,
  &.active:focus,
  &:active.focus,
  &.active.focus {
    color: #fff;
    background-color: #00405f;
    border-color: rgba(0, 0, 0, 0.2);
  }

  &.disabled:hover,
  &[disabled]:hover,
  &.disabled:focus,
  &[disabled]:focus,
  &.disabled.focus,
  &[disabled].focus {
    background-color: #386af5;
    border-color: rgba(0, 0, 0, 0.2);
  }

  & .badge {
    color: #386af5;
    background-color: #fff;
  }
}

.button-login {
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 48px;
  text-align: center;
  vertical-align: bottom;
  font-size: 18px;
  font-weight: 500;
  border-radius: 6px;
}

.form-signin {
  max-width: 480px;
  padding: 70px 20px 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  min-height: 550px;

  @media screen and (max-width: 767px) {
    min-height: 400px;
  }
}

.form-signin-heading {
  text-align: center;
  margin-bottom: 30px;
}

.login {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    padding: 20px 10px;
  }

  &-flex-wrapper {
    flex: 1;
  }

  &--container {
    display: flex;
    flex-direction: row;
    border-color: rgba(0, 24, 42, 0.05);
    box-shadow: 0px 9px 24px -3px rgba(0, 0, 0, 0.16),
      0px 4px 8px -1px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
  }
  &--left-wrapper {
    flex: 1;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(21, 62, 102, 0.1);
    border-radius: 20px 0px 0px 20px;

    @media screen and (max-width: 767px) {
      border-radius: 20px;
    }
  }
  &--right-wrapper {
    width: 50%;
    box-shadow: 0px 0px 4px 0px rgba(21, 62, 102, 0.1);
    background: linear-gradient(140.09deg, #2a3698 10.32%, #7280ff 98.48%);
    border-radius: 0px 20px 20px 0px;
    padding: 68px 56px;
    position: relative;
    overflow: hidden;

    &-cross {
      position: absolute;
      top: 0;
      left: -45px;
    }

    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  &--logo {
    display: block;
    font-size: inherit;
    position: absolute;
    z-index: 1;
    color: white;
    font-size: 32px;
    text-align: center;
  }
  &--title {
    color: $text-1;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 26px;
    text-align: center;
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
  &--linkedIn {
    border-radius: 35px;
    padding: 14px 35px;
    font-size: 18px;
    line-height: 20px;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 11px;
    }
  }
  &--disclaimer {
    color: $text-2;
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    margin: 18px auto 40px;
  }
  &--question {
    font-size: 14px;
    line-height: 18px;
    display: block;
    color: $text-2;
    margin-top: 18px;
    text-align: center;
  }
  &--contact-email {
    color: $brand;
  }
  &--separator {
    border-bottom: 1px solid #d1d1dc;
    width: 100%;
  }
  &--signup {
    margin-top: 32px;
    margin-bottom: 32px;
    text-align: center;
  }
  &--email {
    margin-top: 22px;
    margin-bottom: 32px;
    font-size: 13px;
    text-align: center;
  }
  &--footer {
    // margin-top: 460px;
  }

  &--slider {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 0px;

    .carousel {
      position: initial;
    }
  }

  .form-control {
    width: 100%;
    max-width: inherit;
    border: 1px solid #d1d1dc;
    box-sizing: border-box;
    box-shadow: none !important;
    border-radius: 6px !important;
    height: 48px;
    margin-bottom: 16px;

    &.form-password {
      margin-bottom: 32px !important;
    }
  }
}

.mt100 {
  margin-top: 100px;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

@import "../../../../sass/globals.scss";

.post-onboarding {
  margin-bottom: 20px;

  .custom-card {
    z-index: 100;
    &-header {
      font-weight: bold;
      font-size: 30px;
      line-height: 39px;
      padding: 18px 36px 12px;

      @media (max-width: 500px) {
        padding-left: 15px;
        padding-right: 10px;
        font-size: 26px;
      }
    }
    &-subheading {
      font-size: 20px;
      font-weight: bold;
      color: $text-1;
      opacity: 0.9;

      @media (max-width: 500px) {
        padding-left: 3px;
        padding-right: 10px;
        font-size: 18px;
      }
    }

    &-body {
      padding: 30px 36px;

      @media (max-width: 500px) {
        padding: 24px 18px;
      }

      .invite-btn {
        margin-left: 38px;
      }
    }
  }

  &__headline {
    font-weight: bold;
    font-size: 20px;
    line-height: 1.3;
    color: $text-1;
    margin-bottom: 5px;
  }

  &__subheadline {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.2;
    color: $text-3;
  }
}

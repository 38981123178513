@import "../../../sass/globals.scss";

.card-box {
  background-color: $white;
  border-radius: 18px;
  border: 1px solid $gray-4;
  margin-bottom: 20px;
  position: relative;
}

.card-separator {
  height: 1px;
  background-color: $gray-4;
  left: 0;
  width: 100%;
  position: absolute;
}

.profile-edit-header {
  font-size: 32px;
  font-weight: 600;
  color: black;
}

.profile-edit-section-title {
  color: black;
  font-weight: 600;
}

.profile-edit-progress {
  padding-left: 30px;
  width: 90%;
}

.profile-edit-progress span {
  font-size: 20px;
  font-weight: 600;
  color: $text-1;
}

.welcome-message {
  color: $text-1;
}

.profile {
  &--cover {
    width: 100%;
    height: 216px;
  }
  &--cover-wrapper {
    position: relative;
    border-radius: 18px;
  }
  &--edit-cover {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  &--image {
    position: absolute;
    border: 3px solid $white;
    top: 98px;
  }
  &--edit-image {
    margin-top: 45px;
    margin-bottom: 40px;
  }
  &--row {
    padding-left: 60px;
  }
  &--row-title {
    padding-left: 20px;
    padding-right: 80px;
  }
  &--input {
    padding-left: 20px;
    border-radius: 35px;
    margin-bottom: 25px;
  }
  &--input-hidden {
    display: none;
  }
  &--select {
    padding-left: 20px;
    border-radius: 35px;
    margin-bottom: 25px;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
    appearance: none;

    option {
      display: block;
      width: 100%;
      margin-left: 10px;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 2;
      color: #212529;
    }
  }
  &--textarea {
    border-radius: 4px;
  }
  .form-label {
    color: $text-1;
    font-size: 16px;
    font-weight: 500;
  }
  .form-label-hidden {
    display: none;
  }
}

.vendor-profile-type-list {
  display: flex;
  margin-bottom: 25px;

  .form-check + .form-check {
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .profile--row-title {
    padding: 0 10px;
  }
  .profile--row {
    padding: 5px 0 !important;
    margin: 0 !important;
  }
  .profile-edit--content,
  .profile-edit--footer {
    &.open {
      display: none;
    }
  }
  .profile--input {
    border-radius: 8px;
  }
  .vendor-profile--form,
  .vendor-profile--footer {
    &.open {
      display: none;
    }
  }
}

@media (max-width: 991px) {
  .about .row {
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .about .row {
    padding: 15px 0px;
  }
}

@import "../../../../sass/globals.scss";

.avatar-wrapper {
  display: flex;
  align-items: center;
  padding: 4px 0 8px;
}

.avatar-thumb {
  margin-right: 14px;
}

.avatar-content {
  min-width: 1px;
  width: 100%;
  margin-top: -4px;

  &-heading {
    font-size: 14px;
    font-weight: 500;
    color: $text-1 !important;

    &:hover {
      text-decoration: underline !important;
    }
  }

  &-subheading {
    font-size: 12px;
    line-height: 12px;
    color: $text-3;

    &.avatar-content-subheading-isConnection {
      &::before {
        content: "- ";

        @media screen and (max-width: 767px) {
          content: "";
        }
      }
    }
  }

  &-text {
    color: $text-1;
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-connections {
    display: flex;
    align-items: baseline;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 2px;
    }
  }
}

.ellipsis-line {
  min-width: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
}

.usercard-wrap {
  background-color: #ffffff;
  padding: 12px 16px;
  float: left;
  margin-left: 10px;
}

.usercard-subheadlines {
  line-height: 1.4;
}

.usercard-subheadline {
  color: #000;
  font-size: 14px;
}

.usercard-subheadline a {
  color: #2962ff;
  font-weight: 500;
}

.usercard-wrapper {
  background-color: #ffffff;
}

.usercard-wrapper-header {
  padding: 12px 16px;
}

.usercard-wrapper-title {
  color: #666666;
}

.usercard-wrapper-title a {
  color: #2962ff;
  font-weight: 500;
}

.usercard-wrapper-title img {
  scale: 0.8;
  margin-left: -3px;
  margin-top: -2px;
}

.usercard-subheadlines img {
  scale: 0.8;
  margin-left: -2px;
  margin-top: -2px;
}

.usercard-wrapper-subtitle {
  font-size: 13px;
  color: #666666;
}

.usercard-body {
  padding: 12px 16px;
}

.usercard-img {
  float: left;
  margin-right: 5px;
  margin-top: 2px;
}

.usercard-img img {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-clip: content-box;
  border: 2px solid transparent;
  border-radius: 50%;
  object-fit: cover;
}

.usercard-img-square {
  border: 4px solid transparent !important;
  border-radius: 6px !important;
}

.usercard-body-left {
  margin-left: -15px;
}

.usercard-title {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin-bottom: 0;
}

.usercard-title img {
  margin-top: -4px;
}

.usercard-subtitle {
  color: #666666;
}

.usercard-text {
  margin: 8px 0;
  color: #000000;
  line-height: 21px;
}

.usercard-label {
  color: #2962ff;
  font-weight: 500;
  float: left;
  margin-right: 10px;
}

.usercard-label-text {
  width: 110px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 12px;
}

.usercard-image,
.usercard-image-add {
  cursor: pointer;
}

.usercard-image img {
  width: 100%;
}

.usercard-image-add {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 3px;
  min-height: 57.13px;
}

@media (max-width: 575px) {
  .usercard-image {
    max-width: 202px;
    margin: 0 auto;
  }
  .usercard-img {
    max-width: 50px;
    margin-bottom: 10px;
  }
}

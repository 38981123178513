.btn__show-more {
  border: 2px solid;
  background-color: #fff;
  color: #2962ff;
  border-color: #2962ff;
  border-width: 2px;
  font-weight: 500;
  min-width: 135px;
  font-size: 17px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.overview {
  background-color: #ffffff;
  border-left: 2px solid #cccccc;
  border-right: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  padding: 30px 15px 0 15px;
}

.overview--padding {
  padding: 30px;
}

.overview-header {
  font-size: 26px;
  margin-top: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.9);
}

.overview p {
  margin-bottom: 10px;
}

.border {
  border: none !important;
  padding: 0 15px;
  border-bottom: 1px solid #cccccc !important;
}

.border:last-child {
  border-bottom: none !important;
}

.overview-links {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.overview-link {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.overview-link a {
  background-color: #0073b1;
  color: #fff;
  font-weight: 600;
  padding: 6px 12px;
  border-radius: 2px;
  min-width: 130px;
  text-align: center;
}

a {
  color: #2962ff;
  text-decoration: none;
  background-color: transparent;
}

.overview-link span {
  display: block;
  width: 30px;
  margin-right: 15px;
}

.overview-link img {
  width: 100%;
}

@media (max-width: 767px) {
  .overview-links {
    align-items: flex-start;
    margin-top: 10px;
  }
}

.article-wrapper {
  background-color: #ffffff;
}

.child-article {
  border: none;
  box-shadow: none;
}

.child-article > div.article-body {
  border: none;
  box-shadow: none;
}

.child-articles-header {
  padding-left: 16px;
  margin-top: 2px;
  font-size: 17px;
  font-weight: 700;
}

.section-break-directory {
  margin-top: 20px;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid lightgray;
}

.discussion-article {
  margin-bottom: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 0px 3px rgba(0, 0, 0, 0.2);
}

.discussion-show-more {
  padding-left: 0px;
  padding-right: 0px;
  background-color: #fff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: 0 0 8px;
  padding-bottom: 10px;
}

.rating-badge-category {
  width: 60px;
  height: 50px;
  background-color: #0073b1;
  color: white;
  font-size: 28px;
  font-weight: 700;
  margin-left: auto;
  margin-right: 0;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  user-select: none;
  margin-bottom: 10px;
}

.ratings-popup-header {
  font-weight: 700;
}

.ratings-popup-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

.progress {
  height: 6px;
  width: 60px;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-radius: 0.25rem;
  background-color: #eee;
  border: 0;
  margin-top: 10px;
  appearance: none;
}

.progress-success::-webkit-progress-value,
.progress-success::-moz-progress-bar {
  background-color: #7cbb00;
}

.progress-warning::-webkit-progress-value,
.progress-warning::-moz-progress-bar {
  background-color: #ffbb00;
}

.progress-danger::-webkit-progress-value,
.progress-danger::-moz-progress-bar {
  background-color: #f65314;
}

.directory-connected-label {
  color: #0073b1;
  border-color: #0073b1;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding-top: 5px;
  padding-left: 12px;
  float: right;
}

@import "../../../../sass/globals.scss";

@media (min-width: 768px) {
  .add-post-modal {
    .modal-dialog {
      max-width: 700px;
    }
  }
}

.modal {
  font-size: 14px;
  &-content {
    background: $white;
    border-radius: $border-radius;
    border: $border-card;
  }
}

@media (min-width: 801px) {
  .modal-open {
    padding-right: 0px !important;
  }
}

.modal-title {
  color: $text-1;
  font-size: 34px;
  font-weight: bold;
  padding-left: 15px;
  line-height: 1.2;
}

.modal-section {
  &-title {
    color: $text-1;
    font-size: 19px;
    line-height: 26px;
    font-weight: 700;
    margin-top: 5px;
    margin-bottom: 14px;
    padding: 0;

    &-content {
      border-radius: 6px;
      margin: 10px 0;
      width: 100%;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      color: $text-3;
    }
  }
  &-separator {
    border-top: 1px solid #cccccc;
    padding-top: 10px;
  }
  &-body-left-content {
    border-radius: 6px;
    min-height: 70%;
    margin-top: 10px;
    width: 100%;
  }
  &-body-right-content {
    border-radius: 6px;
    margin-top: 10px;
    padding-top: 10px;
  }
  &-checkbox,
  &-checkbox-content,
  &-radio-content {
    margin-right: 10px;
    margin-bottom: 10px;
    color: $text-1;
    font-size: 18px;
    font-weight: 22px;
  }
  &-body-image {
    height: 20px;
    width: 20px;
    margin-right: 5px;
  }
  &-body-content {
    border-radius: 8px;
    color: $text-1;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    padding-left: 5px;
    text-align: left;
    width: 156px;
    height: 43px;
  }
}

.modal-person-section,
.modal-person-section-role {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  color: $text-2;
  font-size: 17px;
  line-height: 22px;
}
.modal-person-section-input {
  width: 92%;
  border-radius: 6px;
}

.modal-person-section-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-person.hidden {
  opacity: 0;
  display: none;
}

.modal-image-wrapper.hidden {
  opacity: 0;
  display: none;
}

.modal-video-wrapper.hidden {
  opacity: 0;
  display: none;
}

.modal-image-wrapper {
  height: 140px;
}

.modal-image-preview {
  border: 2px solid $gray-4;
  border-radius: 6px;
  width: 155px;
  height: 93px;
}

.modal-close-preview-button {
  background: $white;
  border-radius: 50px;
  border: 2px solid #9597ae;
  border: none;
  color: #9597ae;
  font-weight: 600;
  height: 20px;
  left: 158px;
  position: absolute;
  top: -10px;
  width: 20px;
  font-size: 10px;
}

.modal-cancel-button {
  border-radius: 20px;
  margin-top: 10px;
}

.modal-ok-button {
  border-radius: 20px;
  margin-top: 10px;
  font-weight: 600;
  background-color: $brand;
  color: $white;
  border-color: $brand;
}

.standard-btn {
  min-width: 120px;
}

.person-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.6;
}

.share-group-checkbox {
  display: flex;

  label {
    color: $text-1;
    font-size: 18px;
    display: flex;
    align-items: center;

    span {
      margin-left: 10px;
      font-size: 14px;
      color: $text-3;
    }
  }

  @media screen and (max-width: 767px) {
    label {
      align-items: baseline;
      flex-direction: column;

      span {
        margin-left: 0;
      }
    }
  }
}

.network-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;

  .custom-checkbox-label {
    color: $text-1;
    background-color: #d3e0f5;
    border-radius: 20px;
    border: none;
    outline: none;
    padding: 7px 18px;
    margin-right: 20px;
    font-size: 16px;
    white-space: nowrap;
  }
}

.rich-editor-vendor-btns {
  position: absolute;
  left: 196px;
  bottom: 33px;

  .modal-section-body-content {
    width: 125px;
    height: 38px;
    margin-bottom: 0;
    border-radius: 0;

    @media (max-width: 767px) {
      padding: 0.25rem 0.5rem;
    }

    @media (max-width: 510px) {
      width: auto;

      & span {
        display: none;
      }
    }
  }
}

.modal-section-checkbox-content {
  label.custom-checkbox {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .add-person-modal {
    &.visible {
      width: 90% !important;
      max-width: 90%;
      top: 20px;
      left: 5%;
    }
  }
  .modal-title {
    font-size: 26px;
    line-height: 1.3;
  }
  .modal-rich-editor-section {
    flex-direction: column-reverse;
  }
  .modal-section-body-right-content {
    display: flex;
    flex-wrap: wrap;
  }
  .modal-section-list-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .modal-section-body-content {
    border-radius: 22px;
    padding: 0 20px;
    width: 90%;
  }
  .modal-section-checkbox-content {
    max-width: 50%;
  }
}

@import "../../../sass/globals.scss";

/* .btn__load-more {
    display: block;
    width: 136px;
    padding: 6px 5px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 5px;
    white-space: nowrap;
    text-align: center;
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
    background-color: #ffffff;
    color: #2962ff;
  } */

.notifications--feed {
  margin-top: $margin-top-header;
}
.section-break {
  float: left;
  margin-right: 20px;
  margin-top: 1px;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.sort {
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.sort .custom-select {
  padding: 5px 20px 5px 10px;
  width: initial;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

.sort .select-wrapper {
  position: relative;
}

.sort .select-wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.sort .custom-select:focus {
  border: none;
  box-shadow: none;
}

.sort span {
  color: #888888;
}

.article-label {
  color: #222;
  font-weight: 600;
}

.connected-label {
  background-color: #fff;
  color: $brand;
  border-color: $brand;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  margin-top: -5px;
  display: inline-block;
}

.connect-button {
  background-color: #fff;
  color: $brand;
  border-color: $brand;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;
  margin-top: -5px;
}

.connect-button:hover {
  background-color: rgba(152, 216, 244, 0.25);
  color: #006097;
  border-color: #006097;
  box-shadow: inset 0 0 0 1px #a0c4d9;
}

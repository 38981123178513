@import "../../../sass/globals.scss";

.btn_followed {
  background-color: $brand;
  color: $white;
}

.btn_following,
.btn_followed {
  padding: 6px 12px;
  margin-right: 0 !important;
}

.topic-wrapper {
  background-color: #ffffff;
  border-radius: $border-radius;
  border: $border-card;
}

.topic-link {
  color: $brand;
  display: block;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.topic-description {
  font-size: 15px;
  line-height: 1.4;
  display: block;
  color: $text-3;
}

.border-radius-15 {
  border-radius: 15px;
}

.sharp-symbol {
  font-size: 50px;
  color: $brand;
}

@media (max-width: 768px) {
  .topics--simple-top-banner {
    padding: 0 10px;
  }

  .topics--item-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .topic-wrapper .btn {
    min-width: unset;
  }
  .wrapper {
    &.open {
      display: none;
    }
  }
  .topics--wrapper {
    margin: 10px;
  }
  .topics--item-wrapper {
    padding: 0;
    &:nth-child(2n) {
      padding-left: 15px;
    }
  }
}

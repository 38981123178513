.ask-a-question-form {
  display: flex;
  align-items: center;
  padding: 14px 6px;
  justify-content: space-between;

  .ask-question-input {
    border-radius: 100px;
    cursor: pointer;
    margin-left: 23.5px;
    font-size: 17px;
    padding: 11.5px 24px;
  }
}

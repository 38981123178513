@import "../../../../sass/globals.scss";

.entities-wrapper {
  border-top: 1px solid $gray-3;
  margin-left: -16px;
  margin-right: -16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 10px;
  margin-top: 8px;
}

.entities-wrapper.connections-wrapper {
  padding-left: 32px;
}

.entities {
  &--divider {
    border-top: $border-card;
    width: 100%;
    left: 0;
    bottom: 60px;
    margin-top: 2px;
  }
  &-showmore--button {
    text-align: center;
    bottom: 15px;
    left: 115px;
    margin-top: 4px;
    margin-bottom: -7px;
    > button {
      color: $brand;
    }
  }
}

.btn__homepage {
  display: block;
  width: 136px;
  padding: 6px 5px;
  font-size: 18px;
  border: 2px solid #2962ff;
  border-radius: 5px;
  white-space: nowrap;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }

  &-blue {
    background-color: #2962ff;
    color: #ffffff;

    &:hover {
      background-color: rgba(152, 216, 244, 0.25);
      border-color: #2962ff;
      color: #2962ff;
      box-shadow: inset 0 0 0 1px #a0c4d9;
    }
  }

  &-white {
    background-color: #ffffff;
    color: #2962ff;
    margin-left: 30px;

    &:hover {
      background-color: rgba(152, 216, 244, 0.25);
      color: #006097;
      border-color: #006097;
      box-shadow: inset 0 0 0 1px #a0c4d9;
      text-decoration: none;
    }
  }

  @media (max-width: 991px) {
    & {
      width: 114px;
      font-size: 14px;
      border: 1px solid #2962ff;
    }
  }

  @media (max-width: 575px) {
    &-white {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.btn__load-more {
  background-color: #fff;
  color: #2962ff;
  border-color: #2962ff;
  border-width: 2px;
  font-weight: 600;
  min-width: 120px;
  font-size: 16px;
  padding: 6px 12px;

  &:hover {
    background-color: rgba(152, 216, 244, 0.25);
    color: #006097;
    border-color: #006097;
    box-shadow: inset 0 0 0 1px #a0c4d9;
    text-decoration: none;
  }
}

.square-logo {
  position: absolute;
  left: 35px;
  top: 6px;
  max-width: 160px;
  /* background-color: #fff; */
  padding: 2px;
  background-color: rgba(243, 246, 248, 0.94);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1.5px 3px 0px inset,
    rgba(0, 0, 0, 0.15) 0px 1.5px 3px 0px;
  margin: auto;
  border-radius: 2px;
  border: 4px solid #fff;

  img {
    width: 150px;
    height: 150px;
  }
}

.element-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mobile-navbar-button {
  border: 1px solid $light-blue;
  font-weight: 400 !important;
  min-width: 150px !important;
}

.form-group {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 30px;

  & label.label-field {
    font-weight: bold;
    margin-bottom: 5px;
  }

  & input.input-field,
  & .tel-number-field,
  & .textarea-field,
  & .select-field {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border: 1px solid #c2c2c2;
    box-shadow: 1px 1px 4px #ebebeb;
    -moz-box-shadow: 1px 1px 4px #ebebeb;
    -webkit-box-shadow: 1px 1px 4px #ebebeb;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    padding: 7px;
    outline: none;
    margin-right: 8px;
  }

  & .input-field:focus,
  & .tel-number-field:focus,
  & .textarea-field:focus,
  & .select-field:focus {
    border: 1px solid #0c0;
  }

  & .textarea-field {
    height: 100px;
    width: 55%;
  }

  & input[type="submit"],
  & input[type="button"] {
    border: none;
    padding: 8px 15px 8px 15px;
    background: #ff8500;
    color: #fff;
    box-shadow: 1px 1px 4px #dadada;
    -moz-box-shadow: 1px 1px 4px #dadada;
    -webkit-box-shadow: 1px 1px 4px #dadada;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
  }

  & input[type="submit"]:hover,
  & input[type="button"]:hover {
    background: #ea7b00;
    color: #fff;
  }
}

.form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
}

.spinner {
  float: right;
}

.max-content {
  width: max-content;
}

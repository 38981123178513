@import "../../../../sass/globals.scss";

// Add vendor blocker modal
// Give opacity to background
.add-vendor-blocker-modal {
  background-color: #ffffff;
  border: 1px solid #d3e0f5;
  border-radius: 10px;
  padding: 48px 28px;
  max-width: 400px;
  text-align: center;
  position: absolute;
  z-index: 3;
  left: calc(50% - 200px);
  bottom: 150px;

  box-shadow: 0 5px 10px rgba(24, 45, 76, 0.1);
  -webkit-box-shadow: 0 5px 10px rgba(24, 45, 76, 0.1);
  -moz-box-shadow: 0 5px 10px rgba(24, 45, 76, 0.1);

  p {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 20px;
    color: $text-1;
  }

  .filter-btn-group {
    padding-bottom: 0;
  }

  @media screen and (max-width: 767px) {
    padding: 24px 16px;
  }

  @media screen and (max-width: 476px) {
    width: calc(100% - 60px);
    left: 30px;
  }
}

.add-vendor-blocker-opacity {
  width: 100%;
  min-height: 300px;
  max-height: 750px;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: white;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0) 35%,
    hsla(0, 0%, 100%, 0.7) 50%,
    hsla(0, 0%, 100%, 0.95) 70%,
    #fff
  );

  @media screen and (max-width: 767px) {
    width: calc(100% - 34px);
    left: 17px;
  }
}

.add-vendor-blocker-wrapper {
  max-height: 750px;
  min-height: 300px;
  overflow: hidden;
  position: relative;
  border-radius: 12px;
}

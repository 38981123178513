@import "../../sass/globals.scss";

.max-input-width {
  width: 100%;
}

.signup .signup-radio {
  margin-right: 5px;
}

.signup-attn {
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  display: block;
  max-width: 360px;
  margin: 0 auto;
  color: $text-3;
}

.signup {
  &--wrapper {
    padding-top: 20px;

    @media (max-width: 767px) {
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  &--container {
    max-width: 760px;
    margin: 0 auto;
  }
  &--form {
    border-radius: 20px;
    box-shadow: 0px 0px 4px rgba(21, 62, 102, 0.1);
  }
  &--logo {
    display: block;
    margin-bottom: 30px;
    margin-top: 10px;
    text-align: center;
  }
  &--header {
    color: $text-1;
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 32px;
    &-beta {
      text-align: center;
    }
  }
  &--radio {
    margin: 10px 0;
    display: inline-block;
    font-size: 16px;
  }
  &--form-input {
    border-radius: 6px !important;
    padding-left: 15px !important;
    height: 48px;
    box-shadow: none !important;
    color: $text-1;
    font-size: 16px;
    line-height: 20px;
    margin-right: 0 !important;

    &.none-border {
      border-width: 0 !important;
      caret-color: #0c0;
    }

    &::placeholder {
      color: $text-1;
      font-size: 18px;
      line-height: 20px;
    }
  }
  &--form-label {
    margin: 10px 0 !important;
    color: $text-1;
    font-size: 16px;
  }
  &--form-apply {
    border-radius: 12px;
    height: 60px;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    width: 100%;
    margin-top: 25px;
  }
  &--form-link {
    text-align: center;
    padding-bottom: 37px;
    padding-top: 9px;
    font-size: 18px;
    line-height: 27px;
  }
  &--footer {
    margin-top: 88px;
  }
}

.form-inputs-group {
  margin-top: 32px;
  padding: 0 20px;

  .form-group {
    max-width: 420px;
    margin: 0 auto 20px;

    &-fieldset {
      border-color: #c2c2c2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0 23px;
      overflow: hidden;
      position: absolute;
      border-style: solid;
      border-width: 1px;
      border-radius: 12px;
      pointer-events: none;
      display: none;

      &.has-text {
        display: block;
        border-color: #c2c2c2;
      }

      &.is-focus,
      &.has-text.is-focus {
        display: block;
        border-color: #0c0;
      }

      legend {
        top: -14px;
        position: relative;
        width: auto;
        height: 0;
        padding: 0 5px;
        margin: 0;
        font-size: inherit;
      }

      span {
        color: $text-2;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

@import "../../../sass/globals.scss";

.section-break {
  float: left;
  margin-right: 20px;
  margin-top: 1px;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.sort {
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  font-size: 13px;
}

.sort .custom-select {
  padding: 5px 20px 5px 10px;
  width: initial;
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
}

.sort .select-wrapper {
  position: relative;
}

.sort .select-wrapper::after {
  content: "\25BC";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
  pointer-events: none;
}

.sort .custom-select:focus {
  border: none;
  box-shadow: none;
}

.sort span {
  color: #888888;
}

.article-label {
  color: #222;
  font-weight: 600;
}

.network-list-item {
  margin-bottom: 0px;
  box-shadow: none;
  min-height: 134px;
}

.network-list-item .article-img img {
  width: 50px;
  height: 50px;
}

.network-list-item .article-subheadlines {
  max-width: 95%;
}

.network-list-item .article-subheadline {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.network-list-item .article-text {
  // max-width: 95%;
}

.network-feed--button {
  border-radius: 10px;
  border: 1px solid $gray-4;
  font-weight: 500;
  color: $brand;
  width: 100%;
  &:hover,
  &:focus,
  &:active {
    border-radius: 10px;
    border: 1px solid $gray-4;
    font-weight: 500;
    color: $brand;
  }
}

.network--share-content {
  padding-left: 0;
  max-width: 250px;
  min-width: 250px;
}

.simple-top-banner--background-image {
  max-width: 100%;
}

.network--simple-top-banner-wrapper {
  justify-content: space-between;
  display: flex;
  min-width: calc(100% - 250px);
}

@media (max-width: 768px) {
  .network--share-content,
  .network--popular-topics {
    display: none;
  }
  .network--filter-wrapper {
    .filter-dropdown--wrapper {
      .filter-dropdown {
        margin-left: 0;
      }
    }
  }
  .filter-btn-group {
    padding-bottom: 10px;
  }
  .network--simple-top-banner-wrapper,
  .network--filter-wrapper,
  .network--feed,
  .network--footer {
    &.open {
      display: none;
    }
  }
  .network--simple-top-banner {
    max-width: 100%;
  }
}

.network--feed {
  .article-body.row {
    margin-left: 0;
  }
  .article--image,
  .article-img {
    max-width: 50px;
    width: 50px;
    height: 50px;
  }
}

.invite-card {
  height: 164px;

  p {
    font-size: 18px;
    color: $text-1;
    line-height: 1.4;
  }
}

@media (min-width: 768px) {
  .network--simple-top-banner {
    min-width: calc(100% - 260px) !important;
    flex: unset;
  }
  .network--simple-top-banner-wrapper {
    flex-wrap: unset;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .network--feed {
    .article--content {
      .badge--connect-button {
        min-width: unset;
      }
    }
  }
}
